import { useState } from "react";
import { filterPOIs } from "../../utils/gpx";

function useAmenities() {
  const [amenities, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const getMapBounds = (arrLatLng) => {
    // Get bounds of map based on array of latlng
    const north = Math.max(...arrLatLng.map((latlng) => latlng[0]));
    const south = Math.min(...arrLatLng.map((latlng) => latlng[0]));
    const east = Math.max(...arrLatLng.map((latlng) => latlng[1]));
    const west = Math.min(...arrLatLng.map((latlng) => latlng[1]));

    return {
      north: north,
      south: south,
      east: east,
      west: west,
    };
  };

  const extendBounds = (arrLatLng) => {
    // Extend bounds by 5km in each direction
    const { north, east, south, west } = getMapBounds(arrLatLng);
    const extendedNorth = north + 0.045; // ~5km in degrees of latitude
    const extendedEast =
      east + 0.045 / Math.cos((((north + south) / 2) * Math.PI) / 180); // ~5km in degrees of longitude at the latitude's middle point
    const extendedSouth = south - 0.045; // ~5km in degrees of latitude
    const extendedWest =
      west - 0.045 / Math.cos((((north + south) / 2) * Math.PI) / 180); // ~5km in degrees of longitude at the latitude's middle point

    return setBounds({
      north: extendedNorth,
      east: extendedEast,
      south: extendedSouth,
      west: extendedWest,
    });
  };

  const setBounds = ({ north, east, south, west }) => {
    return `${south}%2C${west}%2C${north}%2C${east}`;
  };

  const createSearchQuery = ({ type, label, bounds }) => {
    return `node%5B%22${type}%22%3D%22${label}%22%5D(${bounds})`;
  };

  const combinePoiItems = (poiItems) => {
    const formattedItems = poiItems.map((item) => `++${item}%3B`).join("\n");
    return `data=%5Bout%3Ajson%5D%5Btimeout%3A25%5D%3B%0A(%0A${formattedItems}%0A)%3B%0Aout+body%3B%0A`;
  };

  const setFormData = (bounds) => {
    const allItems = [
      createSearchQuery({ type: "shop", label: "bicycle", bounds: bounds }),
      createSearchQuery({
        type: "amenity",
        label: "drinking_water",
        bounds: bounds,
      }),
      createSearchQuery({ type: "amenity", label: "toilets", bounds: bounds }),
    ];
    const formData = combinePoiItems(allItems);
    return formData;
  };

  const callApi = async (formData) => {
    // Call Overpass API with custom form data and return amenities
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      };
      const response = await fetch(
        "https://overpass-api.de/api/interpreter",
        requestOptions
      );
      const responseData = await response.json();
      return responseData.elements;
    } catch (error) {
      console.log(error.message);
      setError("Failed to fetch amenities");
      setIsLoading(false);
    }
  };

  const assignAmenities = ({ amenitiesData }) => {
    const toilets = [];
    const drinkingWater = [];
    const bikeShops = [];

    // Loop through all amenities and assign them to the correct array
    amenitiesData.forEach((element) => {
      const data = {
        coords: [element.lat, element.lon],
        name: element.tags.name,
      };
      if (element.tags.amenity === "toilets") {
        toilets.push(data);
      } else if (element.tags.amenity === "drinking_water") {
        drinkingWater.push(data);
      } else if (element.tags.shop === "bicycle") {
        bikeShops.push(data);
      }
    });
    setData({ toilets, drinkingWater, bikeShops });
    setIsLoading(false);
  };

  const updateAmenities = async ({
    bounds = undefined,
    arrLatLng = undefined,
    maxDistance = 1000,
  }) => {
    setIsLoading(true);
    setError("");
    let boundsArg;

    // If bounds are provided, directly define them
    if (bounds) {
      boundsArg = setBounds({
        north: bounds._northEast.lat,
        east: bounds._northEast.lng,
        south: bounds._southWest.lat,
        west: bounds._southWest.lng,
      });
    }
    // Else if arrLatLng is provided, calculate bounds from it
    else if (arrLatLng) {
      boundsArg = extendBounds(arrLatLng);
    }
    // If neither are provided, return
    else {
      return;
    }
    // Call API with custom form data
    const formData = setFormData(boundsArg);
    const amenities = await callApi(formData);

    // If amenities are returned, assign them
    if (amenities?.length > 0) {
      // Filter amenities by distance if arrLatLng is provided
      let filteredAmenities = amenities;
      if (arrLatLng) {
        filteredAmenities = filterPOIs(arrLatLng, amenities, maxDistance);
      }
      assignAmenities({ amenitiesData: filteredAmenities });
    }
    setIsLoading(false);
  };

  return { updateAmenities, amenities, isLoading, error };
}

export default useAmenities;
