import { getTickerScale } from "../../../../utils/calculations";
const params = require("./parameters");

const setText = (
  posX,
  posY,
  text,
  fontSize,
  key,
  anchor,
  alignmentBaseline = "auto"
) => {
  return (
    <text
      x={posX}
      y={posY}
      textAnchor={anchor}
      fontSize={fontSize}
      key={key}
      alignmentBaseline={alignmentBaseline}
    >
      {text}
    </text>
  );
};

const getGradientText = (leftX, rightX, gradient) => {
  const y = params.bottomY + params.offsetGradient / 2;
  const x = leftX + (rightX - leftX) / 2;

  return (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize="smaller"
      key={leftX + 1}
    >
      {`${(gradient * 100).toFixed(1)}`}
    </text>
  );
};

const drawAltLine = (y, dist) => {
  const startX = params.graphStartX - params.offsetX;
  const endX = params.graphStartX + params.graphWidth;
  if (y >= params.graphStartY && y <= params.bottomY) {
    return [
      <line
        {...{
          x1: startX,
          x2: endX,
          y1: y,
          y2: y,
        }}
        stroke="black"
        strokeWidth={0.5}
        strokeDasharray="5,5"
        opacity="30%"
        key={y}
      />,
      setText(startX, y, `${dist}m`, "0.5em", `alt_${y}`, "end", "middle"),
    ];
  }
};

export class Graph {
  constructor(data, graphPrecision, segmentName) {
    this.data = data;
    this.segmentName = segmentName || "";
    this.graphPrecision = graphPrecision;
    this.minAltitude = Math.min(...data.map((i) => i.altitude));
    this.maxAltitude = Math.max(...data.map((i) => i.altitude));
    this.deltaAltitude = this.maxAltitude - this.minAltitude;
    this.gradient = this.deltaAltitude / data[data.length - 1]?.distance;
    this.scaleFactor = this.getFactorScaleY(Math.abs(this.gradient));
    this.scaleY = (params.graphHeight - params.offsetY) / this.deltaAltitude;
    this.scaleX = params.graphWidth / ((data.length - 2) * graphPrecision);
    if (data[data.length - 1] !== data.length * graphPrecision) {
      this.scaleX = params.graphWidth / ((data.length - 1) * graphPrecision);
    }
  }

  // Determine the scale factor for the Y axis
  getFactorScaleY(gradient) {
    if (gradient > 0.04) {
      return 1;
    } else if (gradient > 0.02) {
      return 0.2 + ((gradient - 0.02) / 0.02) * 0.8;
    }
    return 0.2;
  }

  // Set the color of the surface, depending on the gradient
  getFillColor(value) {
    const fillColor = [
      { color: "#d9f09e", limit: 0 },
      { color: "#d9f09e", limit: 0.03 },
      { color: "#ffe642", limit: 0.06 },
      { color: "#f5aa2a", limit: 0.09 },
      { color: "#db7b2b", limit: 0.12 },
      { color: "#c70202", limit: null },
    ];
    for (let i = 0; i < fillColor.length; i++) {
      if (fillColor[i].limit === null || value < fillColor[i].limit) {
        return fillColor[i].color;
      }
    }
  }

  getPolygon(startPoint, endPoint) {
    const leftX = startPoint.distance * this.scaleX + params.graphStartX;
    const rightX = endPoint.distance * this.scaleX + params.graphStartX;
    const leftY =
      params.bottomY -
      (startPoint.altitude - this.minAltitude) *
        this.scaleY *
        this.scaleFactor -
      params.offsetY;
    const rightY =
      params.bottomY -
      (endPoint.altitude - this.minAltitude) * this.scaleY * this.scaleFactor -
      params.offsetY;
    const fill = this.getFillColor(endPoint.gradient);

    return (
      <polygon
        fill={fill}
        stroke="black"
        strokeWidth="0.5"
        points={`${leftX},${params.bottomY} ${leftX},${leftY} ${rightX},${rightY} ${rightX},${params.bottomY}`}
        key={leftX}
      />
    );
  }

  setGraphSegments() {
    // Convenience function for distance markers
    const distMark = (x, dist) => {
      return setText(
        x,
        params.posDistY,
        `${dist / 1000}km`,
        "0.7em",
        `dist_${dist}`,
        "start"
      );
    };
    const arrLines = [];

    // data.forEach((item, index) => {
    for (let index = 0; index < this.data.length - 1; index++) {
      const startPoint = this.data[index];
      const endPoint = this.data[index + 1];
      if (index === 0) {
        // Add initial distance marker
        arrLines.push(distMark(params.graphStartX, 0));
      }
      // Calculate relevant parameters
      const leftX = startPoint.distance * this.scaleX + params.graphStartX;
      const rightX = endPoint.distance * this.scaleX + params.graphStartX;

      // Add surfaces
      arrLines.push(this.getPolygon(startPoint, endPoint));

      // Add distance markers
      if (index !== this.data.length - 2) {
        arrLines.push(distMark(rightX, endPoint.distance));
      }
      // Add gradient
      if (endPoint.distance - startPoint.distance > this.graphPrecision / 2) {
        arrLines.push(getGradientText(leftX, rightX, endPoint.gradient));
      }
    }
    return arrLines;
  }

  // Create graph
  createGraph(width) {
    return (
      <svg
        viewBox={`0 0 ${params.canvasWidth} ${params.canvasHeight}`}
        width={width || params.canvasWidth}
        preserveAspectRatio="xMidYMin slice"
        id="segment-graph"
      >
        <svg className="graph" width={params.canvasWidth}>
          <line {...params.xAxis} stroke="black" strokeWidth={1} key="xAxis" />
          <line {...params.yAxis} stroke="black" strokeWidth={1} key="yAxis" />
          {this.setHorizontalLines()}
          {setText(
            params.graphStartX + 0.5 * params.graphWidth,
            20,
            this.segmentName,
            "1.5em",
            "segmentName",
            "middle"
          )}
          {this.setGraphSegments()}
        </svg>
      </svg>
    );
  }

  // Set the horizontal lines
  setHorizontalLines() {
    let arrLines = [];
    const scale = getTickerScale(
      (this, this.maxAltitude - this.minAltitude) / this.scaleFactor
    );
    // Draw the horizontal lines - maximum 6
    for (let i = 1; i <= 6; i++) {
      arrLines = arrLines.concat(
        drawAltLine(
          params.bottomY -
            ((Math.floor(this.minAltitude / scale) + i) * scale -
              this.minAltitude) *
              this.scaleY *
              this.scaleFactor -
            params.offsetY,
          (Math.floor(this.minAltitude / scale) + i) * scale
        )
      );
    }
    return arrLines;
  }
}

// export const newGraph = (data, graphPrecision) => {
//   const height = 360;
//   const width = 600;
//   const distance = parseInt(data[data.length - 1].distance);
//   const minAltitude = Math.min(...data.map((i) => i.altitude));
//   const maxAltitude = Math.max(...data.map((i) => i.altitude));
//   const deltaAltitude = maxAltitude - minAltitude;
//   const margin = 0.05;

//   // Construct polygon for each interval
//   const polygons = [];
//   for (let i = 0; i < data.length - 1; i++) {
//     const point1 = data[i];
//     const point2 = data[i + 1];
//     const fill = getFillColor(point2.gradient);
//     const scale = 1 - 2 * margin;
//     const offset = margin * deltaAltitude;
//     const polygon = (
//       <polygon
//         points={`
//         ${point1.distance},${-(point1.altitude - minAltitude) * scale - offset}
//         ${point2.distance},${-(point2.altitude - minAltitude) * scale - offset}
//         ${point2.distance},0
//         ${point1.distance},0`}
//         fill={fill}
//         stroke="black"
//         strokeWidth="0.5"
//         key={i}
//       />
//     );
//     polygons.push(polygon);
//   }

//   return (
//     <svg
//       viewBox={`-300 10 ${distance} ${deltaAltitude}`}
//       width={width}
//       height={height}
//       preserveAspectRatio="none meet"
//     >
//       <svg width={distance} height={deltaAltitude}>
//         {/* <!-- Set the origin to the bottom left corner --> */}
//         <g transform={`translate(0,${deltaAltitude})`}>
//           {/* Draw the X-axis */}
//           <line
//             x1="0"
//             y1="0"
//             x2={distance}
//             y2="0"
//             stroke="black"
//             strokeWidth="2"
//           />

//           {/* Draw the Y-axis */}
//           <line
//             x1="0"
//             y1="0"
//             x2="0"
//             y2={-deltaAltitude}
//             stroke="black"
//             strokeWidth="2"
//           />
//           {/* Draw profile */}
//           {polygons}
//         </g>
//       </svg>
//     </svg>
//   );
// };
