import {
  ConvertMySegments,
  ConvertDistance,
  ConvertExplore,
  ConvertID,
  ConvertPercentage,
  ConvertTime,
  ConvertRouteAnalysis,
  ConvertMyRoutes,
} from "../../../utils/agGridConversions";

export const defaultColDef = {
  sortable: true,
  filter: true,
  resizable: true,
};

export const mySegmentCols = [
  { field: "name", headerName: "Segment", cellRenderer: ConvertID },
  {
    field: "distance",
    headerName: "Dist.",
    cellRenderer: ConvertDistance,
  },
  {
    field: "average_grade",
    headerName: "Avg. Grade",
    cellRenderer: ConvertPercentage,
  },
  { field: "pr_time", headerName: "My PR", cellRenderer: ConvertTime },
];
export const mySegmentColsMobile = [
  { field: "name", headerName: "Segment", cellRenderer: ConvertMySegments },
];

export const exploreColsMobile = [
  { field: "name", headerName: "Segment", cellRenderer: ConvertExplore },
];

export const climbStatsColsMobile = [
  {
    headerCheckboxSelection: false,
    checkboxSelection: true,
    width: 50,
  },
  {
    field: "start",
    headerName: "Climbs",
    cellRenderer: ConvertRouteAnalysis,
  },
];

export const myRoutesCols = [
  {
    field: "name",
    headerName: "Route",
    cellRenderer: ConvertMyRoutes,
  },
];

export const userCols = [
  { field: "strava_id", headerName: "Strava ID" },
  { field: "first_name", headerName: "First Name" },
  { field: "last_name", headerName: "Last Name" },
  { field: "created_at", headerName: "Created At" },
  { field: "updated_at", headerName: "Updated At" },
];

export const updateKomCols = [
  { field: "id", headerName: "ID", cellRenderer: ConvertID, width: 150 },
  { field: "xoms.kom", headerName: "KOM", width: 100 },
  { field: "xoms.qom", headerName: "QOM", width: 100 },
  { field: "athlete_count", headerName: "#Athletes", width: 150 },
  { field: "effort_count", headerName: "#Efforts", width: 150 },
  { field: "updatedAt", headerName: "Last update", width: 150 },
];
