import React, { useContext } from "react";

import { FormControl, Input, InputLabel } from "@mui/material";
import { SegmentSearchContext } from "../../../../contexts/SearchContext";

function NumberSegments() {
  const { searchCond, setSearchCond } = useContext(SegmentSearchContext);

  const handleChange = (e) => {
    setSearchCond({
      ...searchCond,
      num_segments: e.target.value,
    });
  };

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor="component-simple">
        Max. number of segments
      </InputLabel>
      <Input
        id="component-simple"
        type="number"
        value={searchCond.num_segments}
        onChange={(e) => handleChange(e)}
      />
    </FormControl>
  );
}

export default NumberSegments;
