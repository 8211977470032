import { configureStore } from "@reduxjs/toolkit";
import gpxDataReducer from "../components/pages/routeanalysis/slicers/gpxDataSlice";
import routeAnalysisParamsReducer from "../components/pages/routeanalysis/slicers/parameterSlice";
import routeAnalysisProfileReducer from "../components/pages/routeanalysis/slicers/profileSlice";
import routeAnalysisPostitionReducer from "../components/pages/routeanalysis/slicers/positionSlice";

export default configureStore({
  reducer: {
    gpxData: gpxDataReducer,
    routeAnalysisParams: routeAnalysisParamsReducer,
    routeAnalysisProfile: routeAnalysisProfileReducer,
    routeAnalysisPosition: routeAnalysisPostitionReducer,
  },
});
