import React from "react";
import { useQuery } from "@tanstack/react-query";

import { useGetStravaUsage } from "../../../adapters/admin.axios";
import { renderObject } from "../../../utils/general";

function StravaApiUsage() {
  const { data: stravaUsage, error: stravaUsageError } = useQuery({
    queryKey: ["admin", "stravaUsage"],
    queryFn: useGetStravaUsage,
    staleTime: 10,
  });

  return (
    <>
      <h3>Strava Usage</h3>
      {stravaUsageError ? (
        stravaUsageError.message
      ) : stravaUsage ? (
        <ul>
          {Object.entries(stravaUsage).map(([key, value]) =>
            renderObject(key, value)
          )}
        </ul>
      ) : null}
    </>
  );
}

export default StravaApiUsage;
