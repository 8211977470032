// Searches ll climbs in input file
export const getAllClimbs = (input, settings) => {
  let climbAjuster = new ClimbAdjuster(input, settings);
  let start = 0;
  let index = 0;
  let noMoreClimbs = false;
  const arrResult = [];

  while (start < input.distance.length && !noMoreClimbs) {
    const result = climbAjuster.findFirstClimb({
      startPoint: start,
    });
    if (result.climbFound) {
      index = index + 1;
      arrResult.push(
        climbAjuster.createClimbData(
          result.climb.start,
          result.climb.end,
          index
        )
      );
      start = result.climb.end;
    } else {
      noMoreClimbs = true;
    }
  }
  const result = climbAjuster.combineClimbs(arrResult);
  return result;
};

export class ClimbAdjuster {
  constructor(data, settings) {
    this.data = data;
    this.dataLength = data.distance.length;
    this.settings = settings;
    this.graphColor = "red";
  }

  // Look for the first climb as of starting point
  findFirstClimb = ({ startPoint = 0 }) => {
    let start = startPoint;
    let end = startPoint;
    let climbFound = false;
    let noMoreSegments = false;
    let climb = { start: 0, end: 0 };
    // Search if starting point is not the last point, no climb found yet and enough distance left
    while (start < this.dataLength - 1 && !climbFound && !noMoreSegments) {
      let dist = 0;
      end = start;
      // Find a segment of at least settings.minLength; stop if end point has been reached
      while (dist < this.settings.minLength && end < this.dataLength - 1) {
        end = end + 1;
        dist = this.data.distance[end] - this.data.distance[start];
      }
      // If a segment with distance > settings.minLength is found, continue
      if (dist >= this.settings.minLength) {
        const elevGain = this.data.elevation[end] - this.data.elevation[start];
        const gradient = elevGain / dist;
        // Segment is potentially a climb if the average gradient > settings.minGradient
        if (gradient > this.settings.minGradient / 100) {
          // Extend the end and shorten the start
          climb = this.extendClimb(start, end);
          climb = this.shortenClimb(climb.start, climb.end);
          // Validate the climb
          climbFound = this.validateClimb(climb.start, climb.end).status;
        }
        // If segment is not a climb, start again from the next starting point
        start = start + 1;
      }
      // If no segment with distance > settings.minLength is found, end main loop
      else {
        noMoreSegments = true; // A later starting point will never result in a long enough segment anymore
      }
    }
    return {
      climbFound: climbFound,
      climb: climb,
    };
  };

  // Extend climb from finish for as long as the altitude can be increased
  extendClimb = (start, end) => {
    let increaseExpected = true;
    let endNew = end;
    // Extends the climb until no more ascend is expected
    while (increaseExpected && endNew <= this.dataLength - 1) {
      // Check if an ascend is expected
      increaseExpected = this.deltaExpected(endNew, "ascend");
      endNew = endNew + 1;
    }
    return { start: start, end: endNew - 1 };
  };

  // Shorten climb from start for as long as the altitude can be decreased
  shortenClimb = (start, end) => {
    let descExpected = true;
    let startNew = start;
    // Shortens the climb until the climb score decreases
    while (descExpected && startNew <= end - 1) {
      // Check if a descend is expected
      descExpected = this.deltaExpected(startNew, "descend");
      startNew = startNew + 1;
    }
    return { start: startNew - 1, end: end };
  };

  // Check if either an increase or a decrease in altitude is expected in the near distance
  deltaExpected = (start, type) => {
    const baseAlt = this.data.elevation[start];
    let i = 0;
    let status = false;
    let altNew = 0;
    let distExtend = 0;
    // Check while no improvement found AND search no further than IMPROVE_DISTANCE
    while (!status && distExtend < this.settings.recoveryDist) {
      altNew = this.data.elevation[start + i];
      if (type === "ascend") {
        status = altNew > baseAlt;
      } else {
        status = altNew < baseAlt;
      }
      i = i + 1;
      distExtend = this.data.distance[start + i] - this.data.distance[start];
    }
    return status;
  };

  // Validate whether climb complies with all constraints
  validateClimb = (start, end) => {
    const dist = this.data.distance[end] - this.data.distance[start];
    const elev = this.data.elevation[end] - this.data.elevation[start];
    const gradient = (elev / dist) * 100;
    const score = dist * gradient;

    if (dist < this.settings.minLength) {
      return { status: false, reason: "Length too short" };
    } else if (gradient < this.settings.minGradient) {
      return { status: false, reason: "Gradient too low" };
    } else if (score < this.settings.minScore) {
      return { status: false, reason: "Score too low" };
    }
    return { status: true };
  };

  // Generate object with all relevant climb data
  createClimbData = (start, end, index) => {
    return {
      start: start,
      end: end,
      index: index,
    };
  };

  combineClimbs = (arrClimbs) => {
    const arrClimbsNew = [];
    let i = 0;
    let indexOffset = 0;
    // Loop through the climbs
    while (i < arrClimbs.length - 1) {
      let canCombine = true;
      let j = 0;
      // Loop through next climbs to see if they can be added, until no longer possible
      while (j < arrClimbs.length - 1 - i && canCombine) {
        j = j + 1;
        canCombine = this.checkCombineClimb(
          { start: arrClimbs[i].start, end: arrClimbs[i].end },
          { start: arrClimbs[i + j].start, end: arrClimbs[i + j].end }
        );
      }
      // Adding i + j failed, so add until i + j - 1; this includes the case where no climb is added
      if (!canCombine) {
        arrClimbsNew.push(
          this.createClimbData(
            arrClimbs[i].start,
            arrClimbs[i + j - 1].end,
            arrClimbs[i].index - indexOffset
          )
        );
        i = i + j;
        // Update climb index
        indexOffset = indexOffset + j - 1;
      }
      // Adding i + j succeeded, where i + j is the last climb; loop stopped since all climbs were covered
      else {
        arrClimbsNew.push(
          this.createClimbData(
            arrClimbs[i].start,
            arrClimbs[i + j].end,
            arrClimbs[i + j].index - indexOffset - j
          )
        );
        i = i + j + 1;
        indexOffset = indexOffset + j;
      }
    }
    // Add last climb to the result if it has not been combined
    if (i === arrClimbs.length - 1) {
      const lastClimb = arrClimbs[arrClimbs.length - 1];
      arrClimbsNew.push(
        this.createClimbData(
          lastClimb.start,
          lastClimb.end,
          lastClimb.index - indexOffset
        )
      );
    }
    return arrClimbsNew;
  };

  checkCombineClimb = (baseClimb, nextClimb) => {
    // Check maxDist between end of first and start of next
    if (
      this.data.distance[nextClimb.start] - this.data.distance[baseClimb.end] <
      this.settings.minDistBetween
    ) {
      if (this.validateClimb(baseClimb.start, nextClimb.end)) {
        return true;
      }
    }
    return false;
  };
}
