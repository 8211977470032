import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CircleMarker, Popup, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import WcIcon from "@mui/icons-material/Wc";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { Link } from "react-router-dom";

const amenityConfig = {
  toilet: {
    type: "toilets",
    color: "red",
    tooltip: "Public Toilet",
  },
  water: {
    type: "drinkingWater",
    color: "blue",
    tooltip: "Water point",
  },
  bikeShop: {
    type: "bikeShops",
    color: "green",
    tooltip: "Bike Shop",
    name: true,
  },
};

// Generates amenity markers based on amenity type
const AmenityMarkers = ({ amenity, data }) => {
  if (!data) return null;
  const arrAmenities = [];
  data[amenity.type]?.forEach((item) => {
    arrAmenities.push(
      <CircleMarker
        center={[item.coords[0], item.coords[1]]}
        color={amenity.color}
        radius={5}
        key={`${amenity.type}${item.coords[0]}_${item.coords[1]}`}
      >
        {/* Add name if provided */}
        {amenity.name && item.name ? (
          <Tooltip>{`${amenity.tooltip} (${item.name})`} </Tooltip>
        ) : (
          <Tooltip>{amenity.tooltip}</Tooltip>
        )}
        <Popup>
          <Link
            to={`https://www.google.com/maps/search/?api=1&query=${item.coords[0]}%2C${item.coords[1]}`}
            target="_blank"
            rel="noreferrer"
          >
            Get directions
          </Link>
        </Popup>
      </CircleMarker>
    );
  });

  return <>{arrAmenities}</>;
};

const IconToilet = () => {
  return <WcIcon sx={{ color: amenityConfig.toilet.color }} fontSize="small" />;
};

const IconBikeshop = () => {
  return (
    <DirectionsBikeIcon
      sx={{ color: amenityConfig.bikeShop.color }}
      fontSize="small"
    />
  );
};
const IconWaterpoint = () => {
  return (
    <LocalDrinkIcon
      sx={{ color: amenityConfig.water.color }}
      fontSize="small"
    />
  );
};

export const AmenitiesCluster = ({ items, selected }) => {
  return (
    <MarkerClusterGroup>
      {selected === "water" ? (
        <AmenityMarkers amenity={amenityConfig.water} data={items} />
      ) : null}
      {selected === "toilet" ? (
        <AmenityMarkers amenity={amenityConfig.toilet} data={items} />
      ) : null}
      {selected === "shop" ? (
        <AmenityMarkers amenity={amenityConfig.bikeShop} data={items} />
      ) : null}
    </MarkerClusterGroup>
  );
};

export const AmenitiesButtons = ({
  value,
  onChange,
  sxGroup,
  sxButton,
  orientation = "vertical",
}) => {
  return (
    <ToggleButtonGroup
      orientation={orientation}
      value={value}
      onChange={onChange}
      exclusive
      aria-label="points of interest"
      size="small"
      sx={sxGroup}
    >
      <ToggleButton value="toilet" aria-label="left aligned" sx={sxButton}>
        <IconToilet />
      </ToggleButton>
      <ToggleButton value="water" aria-label="centered" sx={sxButton}>
        <IconWaterpoint />
      </ToggleButton>
      <ToggleButton value="shop" aria-label="centered" sx={sxButton}>
        <IconBikeshop />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
