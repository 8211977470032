import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageContent } from "../../shared/mui.component";
import StravaConnectButton from "../../shared/stravaButton.component";
import Helmet from "../../shared/helmet";

const NoAccess = () => {
  const navigate = useNavigate();

  return (
    <PageContent>
      <Helmet page="noAccess" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1>No access</h1>
          <img
            src="https://media.giphy.com/media/NpL4D3Oc2bJUMAXF9P/giphy.gif"
            alt="No access"
            style={{ maxWidth: "100%" }}
          />
          <p>Looks like you don't have access to this feature yet.</p>
          <StravaConnectButton />
          <Button onClick={() => navigate("/")}>Bring me home</Button>
        </Box>
      </Box>
    </PageContent>
  );
};

export default NoAccess;
