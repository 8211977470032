let gpxParser = require("gpxparser");

export const parseGPX = (input) => {
  // Takes a gpx file as input and returns an object with distance, elevation, and latlng arrays
  const gpx = new gpxParser(); //Create gpxParser Object
  gpx.parse(input);
  return {
    distance: getDistance(gpx),
    elevation: getElevation(gpx),
    latlng: getLatLng(gpx),
    stats: getStats(gpx),
  };
};

const getDistance = (gpx) => {
  const arrDist = [0].concat(gpx.tracks[0].distance.cumul);
  arrDist.pop();
  return arrDist;
};

const getElevation = (gpx) => {
  const arrElev = gpx.tracks[0].points.map((item) => item.ele);
  return arrElev;
};

const getLatLng = (gpx) => {
  const arrLatLng = gpx.tracks[0].points.map((item) => [item.lat, item.lon]);
  return arrLatLng;
};

const getStats = (gpx) => {
  return {
    name: gpx.tracks[0].name,
    distance: gpx.tracks[0].distance.total,
    elevation: gpx.tracks[0].elevation,
  };
};
