const RedirectStrava = () => {
  // Get query parameters
  const urlParams = new URLSearchParams(window.location.search);

  // Store query parameters in local storage
  localStorage.setItem("token", urlParams.get("jwt"));
  localStorage.setItem("refresh", urlParams.get("refresh"));

  // Do whatever is needed when logged in

  // Redirect to home page
  window.location.replace("/");
};

export default RedirectStrava;
