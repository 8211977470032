import React, { useContext, useEffect, useRef, useState } from "react";

import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { getGeocode } from "../../../../adapters/segments.axios";
import { InputMUI } from "../../../shared/mui.component";
import { usePosition } from "../../../hooks/position";
import { SegmentSearchContext } from "../../../../contexts/SearchContext";

function MapSearch({ mapRef, location, disabled = false }) {
  const { setMapCenter } = useContext(SegmentSearchContext);
  const valueRef = useRef();
  const [showMyLocation, setShowMyLocation] = useState(false);
  const { latitude, longitude, error } = usePosition(true, {
    enableHighAccuracy: true,
  });

  useEffect(() => {
    if (location) {
      handleSearch(location);
    }
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      setShowMyLocation(true);
    }
  }, [latitude, longitude]);

  const handleKeyStroke = (e) => {
    if (e.keyCode === 13) {
      handleSearch(valueRef.current.value);
    }
  };

  const handleGeolocation = () => {
    if (latitude && longitude) {
      mapRef.current.setView([latitude, longitude], 13);
      setMapCenter([latitude, longitude]);
    } else {
      console.log(error);
    }
  };

  const handleSearch = async (input) => {
    const res = await getGeocode(input);
    mapRef.current.setView(res.data, 13);
  };

  const SearchButton = () => (
    <IconButton
      onClick={() => handleSearch(valueRef.current.value)}
      disabled={disabled}
    >
      <SearchIcon />
    </IconButton>
  );

  const MyLocation = () => {
    if (showMyLocation) {
      return (
        <IconButton
          onClick={() => handleGeolocation()}
          disableFocusRipple={true}
        >
          <MyLocationIcon />
        </IconButton>
      );
    }
  };

  return (
    <div className="my-2">
      <InputMUI
        label="Explore on location"
        onKeyDown={(e) => handleKeyStroke(e)}
        inputRef={valueRef}
        InputProps={{
          endAdornment: [
            <SearchButton key="search" />,
            <MyLocation key="location" />,
          ],
        }}
        disabled={disabled}
      />
    </div>
  );
}

export default MapSearch;
