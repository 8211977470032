import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import MyFooter from "./components/footer.component";
import Home from "./components/pages/home/home.component";
import Admin from "./components/pages/admin/admin.component";
import MySegments from "./components/pages/mysegments/mysegments.component";
import SegmentExplore from "./components/pages/explore/mainSegmentExplorer.component";
import SegmentInfo from "./components/pages/segmentInfo/segmentInfo.component";
import RouteAnalysis from "./components/pages/routeanalysis/routeAnalysis.component";
import { AdminRoute } from "./components/privateroute";
import ResponsiveAppBar from "./components/navbar/navbarMUI.component";
import Logout from "./components/logout.component";
import { SegementSearchProvider } from "./contexts/SearchContext";
import NoAccess from "./components/pages/error/noAccess.component";
import NotFound from "./components/pages/error/notFound.component";
import MapPOI from "./components/pages/poi/poiFinder.component";
import About from "./components/pages/about/about.page";
import RedirectStrava from "./components/redirects/strava";
import FeedbackBubble from "./components/navbar/fab.component";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000, // 10 minutes
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <SegementSearchProvider>
          <ResponsiveAppBar />
          <FeedbackBubble />
          <div className="content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/logout" element={<Logout />} />
              <Route element={<AdminRoute />}>
                <Route exact path="/admin" element={<Admin />} />
              </Route>
              <Route path="/mysegments" element={<MySegments />} />
              <Route path="/explore" element={<SegmentExplore />} />
              <Route path="/segments/:segmentId" element={<SegmentInfo />} />
              <Route
                exact
                path="/routes/analysis"
                element={<RouteAnalysis />}
              />
              <Route exact path="/routes/:id" element={<RouteAnalysis />} />
              <Route exact path="/poi" element={<MapPOI />} />
              <Route
                exact
                path="/redirect/strava"
                element={<RedirectStrava />}
              />
              <Route exact path="/noaccess" element={<NoAccess />} />
              <Route exact path="*" element={<NotFound />} />
            </Routes>
          </div>
          {/* <FeedbackButton /> */}
          <MyFooter />
        </SegementSearchProvider>
      </Router>
      {/* Add ReactQueryTools to bottom left */}
      <ReactQueryDevtools initialIsOpen={false} position="left" />
    </QueryClientProvider>
  );
}

export default App;
