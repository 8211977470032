import React, { useState, useMemo, useRef, useLayoutEffect } from "react";
import { getGraphData } from "../../../pages/segmentInfo/calculation";
import { Graph } from "./svg.component";
// import SegmentImage from "./modal.component";
// import toImg from "react-svg-to-image";
import { Button } from "@mui/material";
import { PaperCard } from "../../mui.component";

function GradientWidget({ label, distanceArray, altitudeArray }) {
  const ref = useRef();

  const [pos, setPos] = useState(0);
  const [graphWidth, setGraphWidth] = useState(600);

  // Set the graph width depending on the window size
  useLayoutEffect(() => {
    function handleResize() {
      if (ref.current) {
        setGraphWidth(
          ref.current.offsetWidth - Math.min(48, 2 * ref.current.offsetLeft)
        );
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize(); // Call handleResize immediately to set initial width
    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  const arrPrecision = useMemo(() => {
    // Determine which intervals are allowed
    if (distanceArray) {
      // Get distance from last point
      const distance =
        distanceArray[distanceArray.length - 1] - distanceArray[0];
      if (distance > 20000) {
        return [2000, 4000];
      } else if (distance > 10000) {
        return [1000, 2000];
      } else if (distance > 3000) {
        return [500, 1000];
      } else {
        return [250, 500, 1000];
      }
    }
    return [500];
  }, [distanceArray]);

  const graphPrecision = useMemo(() => {
    return arrPrecision[pos];
  }, [arrPrecision, pos]);

  const ProfileGraph = () => {
    return <div className="overflow">{graphItem}</div>;
  };

  // Get graph
  const graphItem = useMemo(() => {
    const data = getGraphData(graphPrecision, distanceArray, altitudeArray);
    const graphClass = new Graph(data, graphPrecision, label);
    return graphClass.createGraph(graphWidth);
  }, [graphPrecision, distanceArray, altitudeArray, label, graphWidth]);

  // Change graph interval
  const changeGraphInterval = () => {
    if (pos === arrPrecision.length - 1) {
      setPos(0);
    } else {
      setPos(pos + 1);
    }
  };

  if (distanceArray && altitudeArray) {
    return (
      <div ref={ref}>
        <PaperCard title="Gradient widget">
          <ProfileGraph />
          <Button
            variant="outlined"
            onClick={() => changeGraphInterval()}
            className="me-2"
          >
            Change interval
          </Button>
        </PaperCard>
      </div>
    );
  }
  return <></>;
}

export default GradientWidget;
