import { Card } from "@mui/material";
import { decode } from "google-polyline";
import { Polyline, Popup } from "react-leaflet";

export const filterSegmentsMap = (data, segmConds, boundConds) => {
  const dataFiltered = data.filter(function (item) {
    if (filterOptions(segmConds, item) && filterBounds(boundConds, item))
      return true;
    return false;
  });
  // Sort on athlete count and set limit
  const result = dataFiltered
    .slice()
    .sort((a, b) => {
      return b.athlete_count - a.athlete_count;
    })
    .slice(0, segmConds.num_segments);
  return result;
};

// Set filter for options
const filterOptions = (conds, segment) => {
  // Distance
  const filtLowDist = conds.distance.lowBound
    ? segment.distance >= conds.distance.lowBound
    : true;
  const filtUppDist = conds.distance.uppBound
    ? segment.distance <= conds.distance.uppBound
    : true;
  // Gradient
  const filtLowGrad =
    conds.grade.lowBound || conds.grade.lowBound === 0
      ? segment.average_grade >= conds.grade.lowBound
      : true;
  const filtUppGrad = conds.grade.uppBound
    ? segment.average_grade <= conds.grade.uppBound
    : true;
  // Climb category
  const filtLowClimb = conds.climb_category.lowBound
    ? segment.climb_category >= conds.climb_category.lowBound
    : true;
  const filtUppClimb = conds.climb_category.uppBound
    ? segment.climb_category <= conds.climb_category.uppBound
    : true;

  return (
    filtLowDist &&
    filtUppDist &&
    filtLowGrad &&
    filtUppGrad &&
    filtLowClimb &&
    filtUppClimb
  );
};

// Set filter for bounds
const filterBounds = (conds, segment) => {
  const filtLowLat = segment.start_latlng[0] >= conds._southWest.lat;
  const filtUppLat = segment.start_latlng[0] <= conds._northEast.lat;
  const filtLowLng = segment.start_latlng[1] >= conds._southWest.lng;
  const filtUppLng = segment.start_latlng[1] <= conds._northEast.lng;
  return filtLowLat && filtUppLat && filtLowLng && filtUppLng;
};

export const updateSegmentPaths = (arrInput) => {
  const pathPopup = (item) => {
    return (
      <Card sx={{ width: 150, border: "none", boxShadow: "none" }}>
        <strong>{item.name}</strong>
        <br />
        Dist. {(item.distance / 1000).toFixed(1)}km
        <br />
        Grade. {item.average_grade}%
        <br />
        <a href={`/segments/${item.id}`} target="_blank" rel="noreferrer">
          View
        </a>
      </Card>
    );
  };
  const result = [];
  arrInput.forEach((item) => {
    const path = decode(item.polyline);
    result.push(
      <Polyline
        positions={path}
        key={`sgmnt_${item.id}`}
        pathOptions={{ color: "red" }}
        eventHandlers={{
          click: (event) => {
            event.target.openPopup();
            event.target.setStyle({
              color: "blue",
            });
          },
          popupopen: (event) => {
            event.target.setStyle({
              color: "blue",
            });
          },
          popupclose: (event) => {
            event.target.setStyle({
              color: "red",
            });
          },
          mouseover: (event) => {
            event.target.setStyle({
              color: "blue",
            });
            event.target.bringToFront();
          },
          mouseout: (event) => {
            if (event.target.isPopupOpen()) return;
            event.target.setStyle({
              color: "red",
            });
          },
        }}
      >
        {pathPopup(item)}
        <Popup position={item.start_latlng}>{pathPopup(item)}</Popup>
      </Polyline>
    );
  });
  return result;
};
