import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import useDeviceType from "../../hooks/screensize";
import { useMemo } from "react";

const features = [
  {
    title: "Segment explorer",
    description: "Find Strava segments near you",
    image: "/info/explore.gif",
    ref: "/explore",
    button: "Start exploring",
    strava: true,
  },
  {
    title: "Route analysis",
    description: "Get great insights in your next ride",
    image: "/info/route_analysis.gif",
    ref: "/routes/analysis",
    button: "Analyse your next ride",
    strava: false,
  },
  {
    title: "POI finder",
    description: "Find nearby water points, toilets or bike shops",
    image: "/info/poi.gif",
    ref: "/poi",
    button: "Find POIs",
    strava: false,
  },
  {
    title: "Power widget",
    description: "Calculate the required effort to ",
    image: "/info/power_widget.gif",
    ref: "/segments/652851",
    button: "Check it out",
    strava: true,
  },
  {
    title: "KOM/QOM score",
    description: "See how challenging the KOM/QOM time is",
    image: "/info/kom_score.png",
    ref: "/segments/652851",
    button: "Can you beat it?",
    strava: true,
  },
];

function HomeFeatures() {
  const screenSize = useDeviceType();
  const navigate = useNavigate();

  // Set slide percentage based on screen size
  const slidePercentage = useMemo(() => {
    switch (screenSize) {
      case 0:
        return 100;
      case 1:
        return 80;
      case 2:
        return 50;
      case 3:
        return 40;
      case 4:
        return 30;
      default:
        return 100;
    }
  }, [screenSize]);

  const onClick = (ref) => {
    navigate(ref);
  };

  // Feature card component
  const FeatureCard = ({ feature }) => {
    return (
      <Container sx={{ width: "100%" }}>
        <Card
          key={feature.title}
          sx={{
            width: "100%",
            maxWidth: 400,
            margin: "auto",
            border: "none",
          }}
        >
          <CardContent sx={{ position: "relative" }}>
            {feature.strava ? <PoweredByStava /> : null}
            <Typography variant="h5" component="h3">
              {feature.title}
            </Typography>
            <Typography variant="body2" component="p">
              {feature.description}
            </Typography>
          </CardContent>
          {feature.image && (
            <>
              <CardMedia
                component="img"
                image={feature.image}
                alt={feature.title}
                sx={{ height: "250px", objectFit: "contain" }}
              />
            </>
          )}
          <CardActionArea
            onClick={() => onClick(feature.ref)}
            sx={{ height: 50, color: "#1769aa" }}
          >
            {feature.button}
          </CardActionArea>
        </Card>
        <br />
      </Container>
    );
  };

  const PoweredByStava = () => {
    return (
      <img
        // className="em-2"
        src={"/strava/api_logo_pwrdBy_strava_stack_light.png"}
        alt="Powered by Strava"
        title="Powered by Strava"
        style={{
          position: "absolute",
          top: 5,
          right: -15,
          zIndex: 1,
          width: "100px",
          height: "25px",
          objectFit: "contain",
        }}
      />
    );
  };

  // Carousel with feature cards
  const FeatureCards = () => {
    return (
      <div style={{ width: "100%" }}>
        <Carousel
          showArrows={true}
          centerMode={true}
          showThumbs={false}
          centerSlidePercentage={slidePercentage}
          infiniteLoop={true}
        >
          {features.map((feature, index) => (
            <FeatureCard feature={feature} key={index} />
          ))}
        </Carousel>
      </div>
    );
  };

  return (
    <Card sx={{ backgroundColor: "#F3F3F3" }}>
      <CardContent>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          style={{ height: "100%" }}
        >
          <Typography variant="h3" component="h2" color="black" align="center">
            Features
          </Typography>
          <FeatureCards />
        </Grid>
      </CardContent>
    </Card>
  );
}

export default HomeFeatures;
