import React from "react";
import { PaperCard } from "../../../shared/mui.component";

function SegmentLeaderboard({ id }) {
  return (
    <PaperCard title="Strava leaderboard">
      <div className="overflow">
        <iframe
          title={`${id}`}
          height="400px"
          width="960px"
          frameBorder="0%"
          allowtransparency="true"
          scrolling="yes"
          src={`https://www.strava.com/segments/${id}/embed`}
        />
      </div>
    </PaperCard>
  );
}

export default SegmentLeaderboard;
