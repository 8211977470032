import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  TextField,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchMergeActivities,
  useGetAllActivities,
} from "../../../adapters/admin.axios";
import { saveAs } from "file-saver";
import { useIsAuth } from "../../../adapters/users.axios";
import { formatDateAndTime } from "../../../utils/general";
import { AgGridReact } from "ag-grid-react";
import { defaultColDef } from "../../shared/tables/columns";
import { InputMUI } from "../../shared/mui.component";

function MergeActivities() {
  const gridRef = useRef();
  const searchRef = useRef();

  const [id1, setId1] = useState("");
  const [id2, setId2] = useState("");

  const queryClient = useQueryClient();

  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  const { data: activities, refetch: fetchActivities } = useQuery({
    queryKey: ["admin", "activities"],
    queryFn: useGetAllActivities,
    enabled: false,
    staleTime: Infinity,
  });

  const { data, refetch, error, isFetching } = useQuery({
    queryKey: ["admin", "merge"],
    queryFn: () => fetchMergeActivities(id1, id2),
    enabled: false,
    staleTime: Infinity,
  });

  // Search box for users
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(searchRef.current.value);
  }, []);

  const activityCols = [
    {
      field: "start_date",
      headerName: "Date",
      valueFormatter: (params) => formatDateAndTime(params.value),
    },
    { field: "name", width: 200 },
    { field: "id", width: 150 },
  ];

  const ActionButton = () => {
    if (isFetching) {
      return <CircularProgress />;
    } else if (!data || data.type === "application/json") {
      return (
        <Button
          onClick={() => refetch()}
          disabled={!isAuth}
          variant="contained"
        >
          Merge activities
        </Button>
      );
    } else if (data && data.type === "application/gpx+xml") {
      return (
        <>
          <Button onClick={() => download()} variant="contained">
            Download merged activity
          </Button>
          <Button onClick={() => reset()} variant="contained" color="error">
            Reset
          </Button>
        </>
      );
    }
  };

  const download = () => {
    if (data) {
      saveAs(data, "merged_with_prepmyride.gpx");
    }
  };

  const reset = () => {
    setId1("");
    setId2("");
    queryClient.setQueryData(["admin", "merge"], undefined);
  };

  const handleClicked = (e) => {
    console.log(e);
    if (e.data?.id) {
      if (id1 === "") {
        setId1(e.data.id);
      } else if (id2 === "") {
        setId2(e.data.id);
      } else {
        setId1(e.data.id);
        setId2("");
      }
    }
  };

  console.log(id1, id2);
  return (
    <Container
      disableGutters
      className="ag-theme-alpine mb-4"
      align="left"
      style={{ width: "100%" }}
    >
      <h3>Strava merge</h3>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <Button onClick={() => fetchActivities()} variant="outlined">
        List activities
      </Button>
      <br />
      {activities ? (
        <>
          <InputMUI
            label="Search in list"
            inputRef={searchRef}
            placeholder="Search..."
            onChange={onFilterTextBoxChanged}
          />
          <AgGridReact
            ref={gridRef}
            domLayout="autoHeight"
            rowData={activities}
            columnDefs={activityCols}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
            onRowClicked={(e) => handleClicked(e)}
          />
        </>
      ) : null}
      <TextField
        label="Activity ID 1"
        value={id1}
        onChange={(e) => setId1(e.target.value)}
        type="number"
        margin="dense"
        size="small"
      />
      <TextField
        label="Activity ID 2"
        value={id2}
        onChange={(e) => setId2(e.target.value)}
        type="number"
        margin="dense"
        size="small"
      />
      <br />
      <ActionButton />
    </Container>
  );
}

export default MergeActivities;
