import React from "react";
import { AppBar, Container, Grid, Typography } from "@mui/material";
import MoreMenu from "./moreMenu.component";

function Title({ input }) {
  return (
    <>
      <AppBar elevation={1} position="sticky" color="inherit">
        <Container style={{ maxWidth: "1200px" }}>
          <Grid container spacing={0}>
            <Grid item xs={11} container alignContent="center">
              <Grid item xs={12}>
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  {input?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "none", md: "flex" } }}>
                <Typography variant="subtitle2">
                  📍{input?.city}, {input?.state}, {input?.country}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "flex", md: "none" } }}>
                <Typography variant="subtitle2">📍{input?.city}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={1}
              container
              alignContent="center"
              justifyContent="end"
            >
              <MoreMenu stravaID={input?.id} />
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </>
  );
}

export default Title;
