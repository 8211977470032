import {
  Box,
  Card,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import useDeviceType from "../../hooks/screensize";
import StravaConnectButton from "../../shared/stravaButton.component";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../shared/mui.component";
import { useIsAuth } from "../../../adapters/users.axios";
import { useQuery } from "@tanstack/react-query";

const SearchField = () => {
  const valueRef = useRef();

  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/explore?search=${valueRef?.current?.value}`);
  };

  const SearchButton = () => (
    <IconButton onClick={() => handleSearch(valueRef.current.value)}>
      <SearchIcon />
    </IconButton>
  );

  // Ensure hitting enter starts the search
  const handleKeyStroke = (e) => {
    if (e.keyCode === 13) {
      handleSearch(valueRef.current.value);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "8px",
        opacity: "80%",
        width: "80%",
      }}
    >
      <TextField
        label="🚴 Where do you want to go?"
        variant="outlined"
        placeholder="🚴 Where do you want to go?"
        inputRef={valueRef}
        onKeyDown={(e) => handleKeyStroke(e)}
        fullWidth
        InputProps={{
          endAdornment: [<SearchButton key="search" />],
        }}
      />
    </Box>
  );
};

function HomeExplore() {
  const screenSize = useDeviceType();
  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  const StravaField = () => {
    return (
      <Box
        sx={{
          width: "80%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StravaConnectButton />
      </Box>
    );
  };

  const MainField = () => {
    if (isAuth) {
      return <SearchField />;
    } else if (isAuth === undefined) {
      return <Loading />;
    } else {
      return <StravaField />;
    }
  };

  return (
    <Card sx={{ height: screenSize < 2 ? 300 : 400 }}>
      <CardMedia
        image="/alpe-d-huez.jpeg"
        alt="Background"
        sx={{ height: "100%", opacity: "80%" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          style={{ height: "100%" }}
        >
          <Typography
            variant="h1"
            color="white"
            align="center"
            sx={{
              // marginBottom: "1rem",
              maxWidth: "60%",
              fontSize: screenSize < 2 ? 40 : 70,
              fontWeight: "bold",
              fontFamily: "Monospace",
            }}
          >
            The start of all your rides
          </Typography>
          <MainField />
        </Grid>
      </CardMedia>
    </Card>
  );
}

export default HomeExplore;
