import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import UploadRoute from "./upload.component";
import RouteProfile from "./routeProfile/routeProfile.component";
import { parseGPX } from "../../../utils/parseGPX";
import { getAllClimbs } from "./climbFinder";
import ErrorMessage from "../../shared/errorMessage.component";
import ClimbStats from "./climbStats.component";
import SegmentMap from "./map.component";
import { Box, Container, Grid } from "@mui/material";
import { PageContent } from "../../shared/mui.component";
import Helmet from "../../shared/helmet";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateClimbs,
  updateProfile,
  updateSelection,
} from "./slicers/profileSlice";

function RouteAnalysis() {
  // Get id parameter from URL if available
  const { id } = useParams();

  const containerRef = useRef(null);

  const [show, setShow] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  // Get redux store items
  const routeAnalysisParams = useSelector((state) => state.routeAnalysisParams);
  const gpxData = useSelector((state) => state.gpxData.value);
  const profileData = useSelector(
    (state) => state.routeAnalysisProfile.profile
  );

  const dispatch = useDispatch();

  // Set profile data from gpx file
  useEffect(() => {
    if (gpxData && gpxData?.startsWith("<?xml")) {
      dispatch(updateProfile(parseGPX(gpxData)));
    } else if (gpxData) {
      setErrMsg("Could not load route.");
      setShowErr(true);
    }
  }, [dispatch, gpxData]);

  // Calculate climbs
  const calcClimbs = useCallback(() => {
    setShow(true);
    dispatch(updateClimbs(getAllClimbs(profileData, routeAnalysisParams)));
    dispatch(updateSelection([]));
  }, [dispatch, profileData, routeAnalysisParams]);

  useEffect(() => {
    if (profileData) {
      calcClimbs();
    }
  }, [calcClimbs, profileData]);

  // Set helmet depending on whether id is available or not
  const HelmetComponent = () => {
    if (id && profileData) {
      return (
        <Helmet
          title={`PrepMyRide | Routes | ${profileData.stats.name}`}
          description={profileData.stats.description}
        />
      );
    } else if (id) {
      return <Helmet title={`PrepMyRide | Routes | Loading route...`} />;
    } else {
      return <Helmet page="routeAnalysis" />;
    }
  };

  const InfoCard = () => {
    return (
      <Box sx={{ width: "400" }}>
        <p align="left">
          With Route Analysis, you get detailed insights in your upcoming ride:
          <li>Automatic climb discovery</li>
          <li>Show POIs along the way</li>
          <li>
            Add notes to the route <i>(coming soon)</i>
          </li>
        </p>
      </Box>
    );
  };

  return (
    <PageContent>
      <HelmetComponent />
      <Container align="center" disableGutters>
        <Container align="center" disableGutters style={{ maxWidth: "1200px" }}>
          <ErrorMessage show={showErr} setShow={setShowErr} message={errMsg} />
          <Row>
            <h1 align="left">Route Analysis</h1>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                {!show ? <InfoCard /> : null}
                {/* First Icon and its content */}
              </Grid>
              <Grid item xs={12} md={5}>
                {!show ? <UploadRoute /> : null}
                {/* Second Icon and its content */}
              </Grid>
            </Grid>
            {show ? <SegmentMap /> : null}
            <div className="overflow" ref={containerRef}>
              <RouteProfile show={show} containerRef={containerRef} />
            </div>
            <ClimbStats resetClimbs={calcClimbs} />
          </Row>
        </Container>
      </Container>
    </PageContent>
  );
}

export default RouteAnalysis;
