import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as d3 from "d3";
import { Slider } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { updatePosition } from "../slicers/positionSlice";
import { getBase, getClimbs, getPosition, removePosition } from "./utils";
import { downloadPng } from "./download";
import { isMobile } from "react-device-detect";

// Define the dimensions for your SVG and margins
const margin = { top: 25, right: 10, bottom: 20, left: 40 };
const svgWidthMin = 350;
const svgWidthMax = 1200;
const svgHeightMax = 250;

function RouteProfile({ show, containerRef }) {
  // Define the width and height of the SVG
  const [svgWidth, setSvgWidth] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);

  // Set the width and height of the SVG based on the parent container
  useEffect(() => {
    // Define a callback function to handle resizing
    const handleResize = (entries) => {
      for (let entry of entries) {
        // Let width depend on the width of the parent container
        const newWidth = Math.min(
          svgWidthMax,
          Math.max(svgWidthMin, entry.contentRect.width)
        );
        setSvgWidth(newWidth);
        // Height is half the width, but maxed at svgHeightMax
        setSvgHeight(Math.min(svgHeightMax, newWidth / 2));
      }
    };

    // Create a resize observer to update the SVG width and height
    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Clean up
    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [containerRef]);

  // Calculate the width and height of the graph
  const width = svgWidth - margin.left - margin.right;
  const height = svgHeight - margin.top - margin.bottom;

  // Create a ref to the SVG element
  const svgRef = useRef(null);

  // Get redux store items
  const profileData = useSelector(
    (state) => state.routeAnalysisProfile.profile
  );
  const climbs = useSelector((state) => state.routeAnalysisProfile.climbs);
  const position = useSelector((state) => state.routeAnalysisPosition.index);
  const dispatch = useDispatch();

  // Set distance, elevation and stats for convenience
  const dist = useMemo(() => {
    return profileData?.distance || [];
  }, [profileData]);
  const elev = useMemo(() => {
    return profileData?.elevation || [];
  }, [profileData]);
  const stats = useMemo(() => {
    return profileData?.stats || {};
  }, [profileData]);

  // Set scaleX and scaleY
  const scaleX = useMemo(() => {
    if (!dist || dist.length === 0 || !show) return null;
    return d3
      .scaleLinear()
      .domain([0, d3.max(dist)])
      .range([0, width]);
  }, [dist, width, show]);
  const scaleY = useMemo(() => {
    if (!elev || elev.length === 0 || !show) return null;
    const minElevation = d3.min(elev);
    const maxElevation = d3.max(elev);
    const deltaElevation = maxElevation - minElevation;
    return d3
      .scaleLinear()
      .domain([
        minElevation - deltaElevation / 8,
        maxElevation + deltaElevation / 8,
      ])
      .range([height, 0]);
  }, [elev, height, show]);

  // Wrap the below in a callback hook to prevent unnecessary re-renders
  const handleMouse = useCallback(
    (e) => {
      if (isMobile) return;
      // Get the x position of the mouse relative to the SVG
      const [x] = d3.pointer(e);
      const distanceValue = scaleX.invert(x - margin.left); // Adjust for margin
      const index = d3.bisectLeft(dist, distanceValue);
      dispatch(updatePosition(index));
    },
    [dispatch, dist, scaleX]
  );

  // Function to handle slider change
  const handleSliderChange = (e) => {
    const newIndex = parseInt(e.target.value, 10);
    dispatch(updatePosition(newIndex));
  };

  // Render base SVG
  useEffect(() => {
    getBase(
      svgRef,
      dist,
      elev,
      scaleX,
      scaleY,
      handleMouse,
      margin,
      svgWidth,
      svgHeight
    );
  }, [handleMouse, scaleX, scaleY, dist, elev, svgWidth, svgHeight]);

  // // Render title
  // useEffect(() => {
  //   getTitle(svgRef, stats, svgWidth, margin);
  // }, [show, stats, svgWidth]);

  // Add climbs
  useEffect(() => {
    getClimbs(svgRef, climbs, dist, elev, scaleX, scaleY, height);
  }, [climbs, dist, elev, scaleX, scaleY, height]);

  // Add position
  useEffect(() => {
    getPosition(svgRef, position, dist, elev, scaleX, width, height);
  }, [position, dist, elev, scaleX, width, height]);

  // Function to handle the download
  const downloadSvgAsPng = useCallback(() => {
    downloadPng(svgRef, stats);
  }, [stats]);

  // Generate SVG with all profiles and climbs
  return (
    <div style={{ marginBottom: 10 }}>
      {show && (
        <>
          <svg ref={svgRef}></svg>
          <br />
          {isMobile && (
            <Slider
              value={position}
              onChange={handleSliderChange}
              onChangeCommitted={() => removePosition(svgRef)} // This replaces onTouchEnd
              min={0}
              max={dist.length - 1}
              style={{
                width: width,
                marginLeft: margin.left,
                marginRight: margin.right,
                marginTop: 0, // Add some spacing above the slider
              }}
            />
          )}
          {/* <br />
          <Button variant="contained" onClick={downloadSvgAsPng}>
            Export profile
          </Button> */}
        </>
      )}
    </div>
  );
}

export default RouteProfile;
