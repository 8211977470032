import React, { useEffect, useMemo, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

import SideBar from "./sideBar.component";
import StravaConnectButton from "../shared/stravaButton.component";

import SubjectIcon from "@mui/icons-material/Subject";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { useQuery } from "@tanstack/react-query";
import { useGetUser, useIsAdmin, useIsAuth } from "../../adapters/users.axios";

const UserMenu = ({ currentUser }) => {
  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  const { data: isAdmin } = useQuery({
    queryKey: ["auth", "isAdmin"],
    queryFn: useIsAdmin,
    enabled: isAuth === true,
  });

  const { data: athleteInfo } = useQuery({
    queryKey: ["user", "info"],
    queryFn: useGetUser,
    enabled: isAuth === true,
  });

  const settingsLoggedIn = useMemo(
    () => [
      { header: "My segments", link: "/mysegments", icon: <SubjectIcon /> },
      { header: "Logout", link: "/logout", icon: <LogoutIcon /> },
    ],
    []
  );

  const settingsAdmin = useMemo(
    () =>
      settingsLoggedIn.concat([
        {
          header: "Admin",
          link: "/admin",
          icon: <AdminPanelSettingsIcon />,
        },
        {
          header: process.env.REACT_APP_BUILD_DATE || "Not Available",
          link: "#",
          icon: <QueryBuilderIcon />,
        },
      ]),
    [settingsLoggedIn]
  );

  const settingsLoggedOut = useMemo(
    () => [
      {
        header: "Connect with Strava",
        link: StravaConnectButton({ url: true }),
        icon: <LoginIcon />,
      },
    ],
    []
  );

  const [menuItems, setMenuItems] = useState([]);
  const [avatar, setAvatar] = useState("");

  // Set states based on currentUser
  useEffect(() => {
    if (isAdmin) {
      setMenuItems(settingsAdmin);
      setAvatar(athleteInfo?.profile_medium);
    } else if (isAuth) {
      setMenuItems(settingsLoggedIn);
      setAvatar(athleteInfo?.profile_medium);
    } else if (isAuth === false) {
      setMenuItems(settingsLoggedOut);
    }
  }, [
    athleteInfo?.profile_medium,
    currentUser,
    isAdmin,
    isAuth,
    settingsAdmin,
    settingsLoggedIn,
    settingsLoggedOut,
  ]);

  const AvatarItems = () => {
    return (
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <SideBar
          icon={<Avatar alt="Avatar" src={avatar} />}
          menuItems={menuItems}
          header="User Menu"
          anchor="right"
        />
      </Box>
    );
  };

  return (
    <Box marginLeft="1em" sx={{ flexGrow: 0 }}>
      <AvatarItems />
    </Box>
  );
};

export default UserMenu;
