import {
  Alert,
  Box,
  Modal,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import RouteAnalysisUploadStrava from "./uploadStrava.component";
import RouteAnalysisUploadFile from "./uploadFile.component";
import RouteAnalysisUploadRWGPS from "./uploadRWGPS.component";
import RouteAnalysisUploadMyRoutes from "./uploadMyRoutes.component";
import { CardBody, CardFooter } from "react-bootstrap";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 12,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
};

function UploadRoute() {
  const [showModal, setShowModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(null);
  const [error, setError] = useState(null);

  const Item = styled(Paper)(() => ({
    padding: 8,
    textAlign: "center",
    color: "black",
    height: "100px", // Fixed height
    width: "100%", // Full width of the parent container
  }));

  const UploadModal = () => {
    return (
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={modalStyle}>{uploadModal}</Box>
      </Modal>
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const uploadWithStrava = () => {
    setError(null);
    setUploadModal(
      <RouteAnalysisUploadStrava close={closeModal} error={setError} />
    );
    setShowModal(true);
  };

  const uploadWithFile = () => {
    setError(null);
    setUploadModal(
      <RouteAnalysisUploadFile close={closeModal} error={setError} />
    );
    setShowModal(true);
  };

  const uploadWithRWGPS = () => {
    setError(null);
    setUploadModal(
      <RouteAnalysisUploadRWGPS close={closeModal} error={setError} />
    );
    setShowModal(true);
  };

  const uploadWithMyRoutes = () => {
    setError(null);
    setUploadModal(
      <RouteAnalysisUploadMyRoutes close={closeModal} error={setError} />
    );
    setShowModal(true);
  };

  const UploadOptions = () => {
    return (
      <>
        <Item onClick={() => uploadWithMyRoutes()} elevation={5}>
          <CardBody>
            <img src="/other/my_routes.svg" height="48" alt="" />
          </CardBody>
          <CardFooter>
            <Typography>My Strava Routes</Typography>
          </CardFooter>
        </Item>
        <Item onClick={() => uploadWithStrava()} elevation={5}>
          <CardBody>
            <img src="/strava/strava_icon.svg" height="48" alt="" />
          </CardBody>
          <CardFooter>
            <Typography>Strava Route ID</Typography>
          </CardFooter>
        </Item>
        <Item onClick={() => uploadWithRWGPS(true)} elevation={5}>
          <CardBody>
            <img src="/rwgps/rwgps_icon.png" height="48" alt="" />
          </CardBody>
          <CardFooter>
            <Typography>RideWithGPS Route ID</Typography>
          </CardFooter>
        </Item>
        <Item onClick={() => uploadWithFile()} elevation={5}>
          <CardBody>
            <img src="/other/upload_from_device.svg" height="48" alt="" />
          </CardBody>
          <CardFooter>
            <Typography>Upload from device</Typography>
          </CardFooter>
        </Item>
      </>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ maxWidth: 400 }}>
        <UploadModal />
        <Typography sx={{ textAlign: "left" }}>
          <strong>Select a source</strong>
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {error}
          </Alert>
        ) : null}
        <Stack
          spacing={2}
          direction="row"
          sx={{ marginTop: 2 }} // Align items to the left
          useFlexGap
          flexWrap="wrap"
        >
          <UploadOptions />
        </Stack>
      </Box>
    </Box>
  );
}

export default UploadRoute;
