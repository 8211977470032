import { Button } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";

function ConfirmModal({ show, close, message, onConfirm }) {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button severity="error" onClick={close}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ConfirmModal;
