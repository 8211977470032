import { Alert, Container } from "@mui/material";
import { useEffect, useState } from "react";
import MapWrapper, {
  CustomButton,
} from "../../shared/map/mapWrapper.component";
import useAmenities from "../../hooks/amenities";
import { PageContent } from "../../shared/mui.component";
import { AmenitiesButtons, AmenitiesCluster } from "../../shared/map/poiItems";
import Helmet from "../../shared/helmet";

function POIFinder() {
  const [map, setMap] = useState(null);
  const { updateAmenities, amenities, error: errorPOI } = useAmenities();
  const [poi, setPoi] = useState("water");
  const [calcPOIs, setCalcPOIs] = useState(true); // If true, POIs are calculated on button click. If false, refresh button is shown.
  const [initialized, setInitialized] = useState(false);

  const handlePOISelection = (event, selection) => {
    if (selection !== null) {
      setPoi(selection);
    }
  };

  useEffect(() => {
    if (calcPOIs && initialized) {
      updateAmenities({ bounds: map?.getBounds() });
      setCalcPOIs(false);
    } else {
      setInitialized(true);
    }
  }, [calcPOIs, initialized, map, poi, updateAmenities]);

  return (
    <PageContent>
      <Helmet page="poi" />
      <Container
        align="center"
        disableGutters
        sx={{ height: "400px", maxWidth: "1200px" }}
      >
        <h1 align="left">POI Finder</h1>
        <h5 align="left">Find nearby water points, toilets or bike shops</h5>

        {errorPOI ? (
          <Alert
            severity="error"
            children={errorPOI}
            sx={{ marginBottom: 2 }}
          />
        ) : null}

        <MapWrapper mapRef={setMap} showLocation={true}>
          <AmenitiesCluster items={amenities} selected={poi} />
          <CustomButton
            label="Search this area"
            handleClick={() => setCalcPOIs(true)}
          />
        </MapWrapper>
        <AmenitiesButtons
          value={poi}
          onChange={handlePOISelection}
          orientation="horizontal"
          sxGroup={{ marginTop: 2 }}
        />
      </Container>
    </PageContent>
  );
}

export default POIFinder;
