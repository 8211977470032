import { createSlice } from "@reduxjs/toolkit";

export const routeAnalysisParamsSlice = createSlice({
  name: "routeAnalysisParams",
  initialState: {
    minLength: 500,
    minGradient: 3,
    minDistBetween: 500,
    minScore: 3500,
    recoveryDist: 500,
  },
  reducers: {
    updateParams: (state, action) => {
      const { minLength, minGradient, minDistBetween, minScore, recoveryDist } =
        action.payload;
      state.minLength = minLength !== undefined ? minLength : state.minLength;
      state.minGradient =
        minGradient !== undefined ? minGradient : state.minGradient;
      state.minDistBetween =
        minDistBetween !== undefined ? minDistBetween : state.minDistBetween;
      state.minScore = minScore !== undefined ? minScore : state.minScore;
      state.recoveryDist =
        recoveryDist !== undefined ? recoveryDist : state.recoveryDist;
    },
    resetParams: (state) => {
      state.minLength = 500;
      state.minGradient = 3;
      state.minDistBetween = 500;
      state.minScore = 3500;
      state.recoveryDist = 500;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateParams, resetParams } = routeAnalysisParamsSlice.actions;

export default routeAnalysisParamsSlice.reducer;
