import axios from "./axios_interceptor";

export async function addMessage(msgObject) {
  try {
    return await axios.post("/messages/", msgObject);
  } catch (err) {
    return err.response;
  }
}

export async function getAllMessages() {
  const result = await axios.get("/messages/all");
  return result.data;
}

export async function deleteMessage(uuid) {
  console.log(uuid);
  const result = await axios.delete(`/messages/${uuid}`);
  return result.data;
}
