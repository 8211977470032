import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { InputMUI, PageContent } from "../../shared/mui.component";
import { mySegmentColsMobile } from "../../shared/tables/columns";
import Helmet from "../../shared/helmet";
import StravaConnectButton from "../../shared/stravaButton.component";
import { Loading } from "../../shared/mui.component";
import { useGetMySegments, useIsAuth } from "../../../adapters/users.axios";
import { useQuery } from "@tanstack/react-query";

function MySegments() {
  const [columnWidth, setColumnWidth] = useState(undefined);
  const divRef = useRef();
  const gridRef = useRef();

  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  const { data: mySegments, refetch } = useQuery({
    queryKey: ["user", "segments"],
    queryFn: useGetMySegments,
    enabled: isAuth === true,
  });

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  useEffect(() => {
    if (divRef.current) {
      setColumnWidth(divRef.current.offsetWidth);
    }
  }, []);

  const colDefMobile = {
    resizable: true,
    width: columnWidth,
    cellStyle: () => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }),
  };

  const SegmentOverview = () => {
    if (mySegments && mySegments.length === 0) {
      return <>Loading</>;
    } else if (mySegments && mySegments[0] !== "ERROR") {
      return (
        <>
          <InputMUI
            label="Search in My Segments"
            id="component-simple"
            onChange={(e) => onFilterTextBoxChanged(e)}
            InputProps={{
              endAdornment: [<SearchIcon key="search" />],
            }}
          />
          <AgGridReact
            ref={gridRef}
            domLayout="autoHeight"
            rowData={mySegments}
            rowHeight={60}
            columnDefs={mySegmentColsMobile}
            defaultColDef={colDefMobile}
            pagination={true}
            paginationPageSize={25}
          />
          <div className="mt-2 mb-5">
            <Button variant="outlined" onClick={() => refetch()}>
              Update my segments
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>No segments found. Go to Strava and "star" your favorite segments!</>
      );
    }
  };

  const ContentAuth = () => {
    return (
      <>
        <p align="left">
          All starred segments on Strava will be listed here. If you don't see
          all your segments, use the "Update" button below or try to log out and
          reconnect with Strava.
        </p>
        <div className="my-1">
          <Button variant="outlined" component={Link} to={"/explore"}>
            Explore all segments
          </Button>
        </div>
        <div
          className="ag-theme-alpine"
          align="left"
          style={{ width: "100%" }}
          ref={divRef}
        >
          <SegmentOverview />
        </div>
      </>
    );
  };

  const ContentNoAuth = () => {
    return (
      <>
        <p align="left">
          You need to be connected with Strava to see your starred segments.
        </p>
        <StravaConnectButton />
      </>
    );
  };

  const Content = () => {
    if (isAuth) {
      return <ContentAuth />;
    } else if (isAuth === undefined) {
      return <Loading />;
    } else {
      return <ContentNoAuth />;
    }
  };

  return (
    <PageContent>
      <Helmet page="mySegments" />
      <div align="center">
        <div align="left" style={{ maxWidth: "800px" }}>
          <h1 align="left">My segments</h1>
          <Content />
        </div>
      </div>
    </PageContent>
  );
}

export default MySegments;
