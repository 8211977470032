import React from "react";
import { Slider } from "@mui/material";

function ScoreSlider({ score = 0, hide = false }) {
  let thumbOpacity = "90%";
  if (hide) {
    thumbOpacity = "0%";
  }

  return (
    <Slider
      defaultValue={score}
      aria-label="Default"
      valueLabelDisplay="auto"
      disabled={true}
      marks={[
        {
          value: 0,
          label: "0",
        },
        {
          value: 100,
          label: "100",
        },
      ]}
      sx={{
        "& .MuiSlider-rail": {
          backgroundImage: "linear-gradient(.25turn, #00ff00, #FF0000)",
        },
        "& .MuiSlider-track": {
          opacity: "0%",
        },
        "& .MuiSlider-thumb": {
          color: "lightgrey",
          opacity: thumbOpacity,
        },
      }}
    />
  );
}

export default ScoreSlider;
