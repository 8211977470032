import { Link } from "react-router-dom";
import { convertNum, convertToHHMMSS } from "./general";
import { Typography } from "@mui/material";

export const ConvertTime = (cell) => {
  return <p>{convertToHHMMSS(cell.value, true)}</p>;
};
export const ConvertID = (cell) => {
  // return <a href={`/segments/${cell.data.id}`}>{cell.value}</a>;
  return (
    <Link
      to={`/segments/${cell.data.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {cell.value}
    </Link>
  );
};

export const ConvertMySegments = (cell) => {
  return (
    <>
      <Typography variant="body1">
        <Link to={`/segments/${cell.data.id}`}>{cell.value}</Link>
      </Typography>
      <Typography variant="body2">
        {(cell.data.distance / 1000).toFixed(1)}km | {cell.data.average_grade}%
        |{" "}
        {cell.data.pr_time ? convertToHHMMSS(cell.data.pr_time, true) : "No PR"}
      </Typography>
    </>
  );
};

export const ConvertExplore = (cell) => {
  return (
    <>
      <Typography variant="body1">
        <Link to={`/segments/${cell.data.id}`}>{cell.value}</Link>
      </Typography>
      <Typography variant="body2">
        {(cell.data.distance / 1000).toFixed(1)}km | {cell.data.average_grade}%
        |{" "}
        {cell.data.kom?.time
          ? convertToHHMMSS(cell.data.kom.time, true)
          : "No KOM"}{" "}
        |{" "}
        {cell.data.qom?.time
          ? convertToHHMMSS(cell.data.qom.time, true)
          : "No QOM"}{" "}
        | {ConvertClimbCat(cell.data.climb_category)}
      </Typography>
    </>
  );
};

export const ConvertRouteAnalysis = (cell) => {
  const stats = cell.data.stats;
  return (
    <>
      <Typography variant="body1">Climb {cell.data.index}</Typography>
      <Typography variant="body2">
        <img
          src="/map/start.png"
          alt="start"
          style={{ height: "1em", verticalAlign: "middle" }}
        />
        {(stats.distStart / 1000).toFixed(1)}km -{" "}
        <img
          src="/map/gradient.png"
          alt="gradient"
          style={{ height: "1em", verticalAlign: "middle" }}
        />
        {convertNum(
          ((stats.elevEnd - stats.elevStart) /
            (stats.distEnd - stats.distStart)) *
            100,
          1,
          "%"
        )}{" "}
        -{" "}
        <img
          src="/map/distance.png"
          alt="distance"
          style={{ height: "1em", verticalAlign: "middle" }}
        />
        {((stats.distEnd - stats.distStart) / 1000).toFixed(1)}km -{" "}
        <img
          src="/map/elevation.png"
          alt="elevation"
          style={{ height: "1em", verticalAlign: "middle" }}
        />
        {(stats.elevEnd - stats.elevStart).toFixed(0)}m
      </Typography>
    </>
  );
};

// export const GetInfo = (cell) => {
//   return (
//     <IconButton onClick={() => openClimbAnalysis(cell.data)}>test</IconButton>
//   );
// };

export const ConvertMyRoutes = (cell) => {
  return (
    <>
      <Typography variant="body1">{cell.data.name}</Typography>
      <Typography variant="body2">
        {(cell.data.distance / 1000).toFixed(1)}km |{" "}
        {cell.data.elevation_gain.toFixed(0)}m
      </Typography>
    </>
  );
};

export const ConvertDistance = (cell) => {
  return <p>{(cell.value / 1000).toFixed(1)}km</p>;
};
export const ConvertPercentage = (cell) => {
  return <p>{cell.value}%</p>;
};

export const ConvertClimbCat = (cell) => {
  if (cell === 0) {
    return "NC";
  }
  if (cell === 1) {
    return "4";
  }
  if (cell === 2) {
    return "3";
  }
  if (cell === 3) {
    return "2";
  }
  if (cell === 4) {
    return "1";
  }
  if (cell === 5) {
    return "HC";
  }
  return "NC";
};
