import axios from "./axios_interceptor";

export async function getSegmentInfo(id) {
  try {
    const result = await axios.get(`/segment/${id}/info`, {
      withCredentials: true,
    });
    return result.data;
  } catch (err) {
    console.log("Error in getSegmentInfo");
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function useGetSegmentInfo({ queryKey }) {
  const [_key1, id, _key3] = queryKey;
  const result = await axios.get(`/segment/${id}/info`);
  return result.data;
}

export async function useGetSegmentProfile({ queryKey }) {
  const [_key1, id, _key3] = queryKey;
  const result = await axios.get(`/segment/${id}/profile`);
  return result.data;
}

export async function useGetWeather({ queryKey }) {
  const [_key1, lat, lng] = queryKey;
  const result = await axios.get(`/weather?lat=${lat}&lng=${lng}`);
  return result.data;
}

export async function useGetExplore({ queryKey }) {
  const [_key1, _key2, bounds, filters, scrape, scoreFilter] = queryKey;
  const params = {
    ne_lat: bounds._northEast.lat,
    ne_lng: bounds._northEast.lng,
    sw_lat: bounds._southWest.lat,
    sw_lng: bounds._southWest.lng,
    climb_cat_min: filters.climb_category.lower,
    climb_cat_max: filters.climb_category.upper,
    distance_min: filters.distance.lower,
    distance_max: filters.distance.upper,
    grade_min: filters.grade.lower,
    grade_max: filters.grade.upper,
    score_min: 10,
    score_max: 80,
    limit: filters.limit,
    scrape: scrape,
  };
  let result = null;
  if (scoreFilter) {
    result = await axios.get("/segment/explore_beta", { params: params });
  } else {
    result = await axios.get("/segment/explore", { params: params });
  }

  return result.data;
}

export async function updateSegment(id) {
  try {
    return await axios.put(`/segment/${id}/update`);
  } catch (err) {
    return err.response;
  }
}

export async function getGeocode(value) {
  try {
    const result = await axios.get(`/geocode?location=${value}`);
    return { status: true, data: result.data };
  } catch (err) {
    console.log("Something went wrong");
    console.log(err.response.data);
    return { status: false, data: err.response.data };
  }
}
