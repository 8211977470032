import { linearInterpolation } from "simple-linear-interpolation";

// USED
/**
 * Given input profileArray, calculates the elevation for a predefined set of distance points
 *
 * @param {object} profileArray - Array of objects {distance, elevation},
 * @param {number} distanceTotal - Total distance between start and end of profileArray
 * @param {number} precision - Step size between two points (in meters)
 *
 * @returns {object} - Array with objects {distance, elevation, gradient}
 * @todo - Decide how to treat the remainging distance after the last step
 */
export function getGraphData(precision, arrDistance, arrAltitude) {
  if (arrDistance && arrAltitude) {
    const distance = arrDistance[arrDistance.length - 1] - arrDistance[0];
    const numberSteps = Math.floor(distance / precision);
    const result = [];
    const tempProfileArray = [];
    arrDistance.forEach((item, index) => {
      tempProfileArray.push({
        x: item - arrDistance[0],
        y: arrAltitude[index],
      });
    });

    const calculate = linearInterpolation(tempProfileArray);
    let previousDistance = 0;
    let previousElevation = 0;
    let distanceStep = 0;
    let altitudeStep = 0;
    for (let i = 0; i <= numberSteps; i += 1) {
      // First step
      if (i === 0) {
        altitudeStep = arrAltitude[i];
        result.push({ distance: 0, altitude: altitudeStep, gradient: 0 });
        previousDistance = 0;
        previousElevation = altitudeStep;
      }
      // Other steps
      else {
        distanceStep = i * precision;
        altitudeStep = Math.round(calculate({ x: distanceStep }));
        result.push({
          distance: distanceStep,
          altitude: altitudeStep,
          gradient:
            (altitudeStep - previousElevation) /
            (distanceStep - previousDistance),
        });
        previousDistance = distanceStep;
        previousElevation = altitudeStep;
      }
    }

    // See @todo
    if (distance > numberSteps * precision) {
      distanceStep = distance;
      altitudeStep = tempProfileArray[tempProfileArray.length - 1].y;
      result.push({
        distance: distance.toFixed(0),
        altitude: altitudeStep,
        gradient:
          (altitudeStep - previousElevation) /
          (distanceStep - previousDistance),
      });
    }
    return result;
  }
  return [];
}
