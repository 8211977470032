import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
} from "@mui/material";
import MapSearch from "./subComponents/mapSearch.component";
import { SegmentSearchContext } from "../../../contexts/SearchContext";
import MapBase from "./subComponents/mapBase.component";
import { filterSegmentsMap, updateSegmentPaths } from "./utils";
import { useGetExplore } from "../../../adapters/segments.axios";

import { usePosition } from "../../hooks/position";
import { myLocationIcon } from "../../shared/map/icons";
import { useIsAdmin } from "../../../adapters/users.axios";
import { useQuery } from "@tanstack/react-query";

function ExploreMap({ location, disabled = false }) {
  const {
    segments,
    setSegments,
    selectedSegments,
    setSelectedSegments,
    searchCond,
    mapCenter,
    mapBounds,
  } = useContext(SegmentSearchContext);

  const [scoreFilter, setScoreFilter] = useState(false); //

  // Fetch isAdmin from backend
  const { data: isAdmin } = useQuery({
    queryKey: ["auth", "isAdmin"],
    queryFn: useIsAdmin,
  });

  // Fetch segments from backend
  const {
    data: segmentsNew,
    refetch: refetchExplore,
    isFetching: isFetchingExplore,
  } = useQuery({
    queryKey: [
      "segments",
      "explore",
      mapBounds,
      searchCond,
      isAdmin,
      scoreFilter,
    ],
    queryFn: useGetExplore,
    enabled: false,
  });

  const { latitude, longitude } = usePosition(true, {
    enableHighAccuracy: true,
  });

  const mapRef = useRef();
  const [segmentsPaths, setSegmentsPaths] = useState([]); // All segments on map and comply with options

  useEffect(() => {
    if (segmentsNew) {
      const result = segments;
      // List all segment IDs already found before
      const arrOldIds = result.map(({ id }) => id);
      // Loop through new segments and add if not yet present
      segmentsNew.forEach((element) => {
        const isDuplicate = arrOldIds.includes(element.id);
        if (!isDuplicate) {
          result.push(element);
        }
      });
      setSegments([...result]);
    }
  }, [segments, segmentsNew, setSegments]);

  const updateSelectedSegments = useCallback(() => {
    const result = filterSegmentsMap(segments, searchCond, mapBounds);
    setSelectedSegments(result);
  }, [segments, searchCond, mapBounds, setSelectedSegments]);

  useEffect(() => {
    updateSelectedSegments();
  }, [updateSelectedSegments]);

  // Update segment paths on change of selectedSegments
  useEffect(() => {
    const newPaths = updateSegmentPaths(selectedSegments);
    setSegmentsPaths(newPaths);
  }, [selectedSegments, setSegmentsPaths]);

  const clickClear = async () => {
    setSegments([]);
  };

  const Loader = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  };

  const CurrentLocation = () => {
    if (latitude === undefined || longitude === undefined) return null;
    return (
      <>
        <Marker position={[latitude, longitude]} icon={myLocationIcon}>
          <Tooltip>Your location</Tooltip>
        </Marker>
      </>
    );
  };

  // Main component
  return (
    <div className="my-1" style={{ zIndex: -99 }}>
      <div className="mb-3">
        <MapSearch mapRef={mapRef} location={location} disabled={disabled} />
        <MapContainer
          ref={mapRef}
          style={{ height: "50vh", width: "50wh" }}
          center={mapCenter}
          zoom={13}
          scrollWheelZoom={true}
        >
          <MapBase mapRef={mapRef} />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <CurrentLocation />
          {segmentsPaths}
        </MapContainer>
      </div>
      <Button
        variant="contained"
        color="error"
        onClick={() => clickClear()}
        className="m-2"
        disabled={disabled}
      >
        Clear all{" "}
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          refetchExplore();
        }}
        className="m-2"
        disabled={disabled}
      >
        Load segments
      </Button>
      {isAdmin ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={scoreFilter}
              onChange={() => setScoreFilter(!scoreFilter)}
            />
          }
          label="Score filter"
        />
      ) : null}
      {isFetchingExplore ? <Loader /> : null}
    </div>
  );
}

export default ExploreMap;
