import React, { useState } from "react";
import { Alert, Button, Container, TextField, styled } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { callMergeGpx } from "../../../adapters/admin.axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function MergeGpx() {
  const [fileOne, setFileOne] = useState(null);
  const [fileTwo, setFileTwo] = useState(null);
  const [error, setError] = useState(null);

  const handleReset = () => {
    setFileOne(null);
    setFileTwo(null);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    // Only continue if there are files
    if (files.length > 0) {
      // First file if not already set
      if (fileOne === null) {
        setFileOne(files[0]);
      } else if (fileTwo === null) {
        setFileTwo(files[0]);
      } else {
        // Both files are set, reset
        setFileOne(files[0]);
        setFileTwo(null);
      }
      if (files.length > 1) {
        setFileTwo(files[1]);
      }
    }
  };

  const { mutate } = useMutation({
    mutationFn: callMergeGpx,
    onSuccess: (data) => {
      console.log(data);
    },
  });

  const handleSubmit = () => {
    if (!fileOne || !fileTwo) {
      setError("Please select two files.");
      return;
    }
    const formdata = new FormData();
    formdata.append("files", fileOne);
    formdata.append("files", fileTwo);

    mutate(formdata);
  };

  return (
    <Container disableGutters align="left" style={{ width: "100%" }}>
      <h3>GPX merge</h3>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <TextField
        type="file"
        inputProps={{ multiple: true, accept: ".gpx" }}
        onChange={handleFileChange}
      />
      <br />
      {fileOne && <p>File 1: {fileOne.name}</p>}
      {fileTwo && <p>File 2: {fileTwo.name}</p>}
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
      >
        Upload
      </Button>
      <Button variant="contained" color="error" onClick={() => handleReset()}>
        Reset
      </Button>
    </Container>
  );
}

export default MergeGpx;
