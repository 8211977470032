import React from "react";
import GradientWidget from "../widgets/graph/graph.component";
import PowerWidget from "../widgets/power/power.component";

import { Grid } from "@mui/material";
import SegmentInfoMap from "../widgets/map/map.component";
import ClimbStats from "./stats.component";

function ClimbInfo({ climbInfo, climbProfile }) {
  // climbProfile = { { distance: [], altitude: [] }, latlng: [] } };
  // climbInfo = { label: "", distance: 0, elevationGain: 0, gradient: 0, startsAt: 0, endsAt: 0, score: 0 };

  // Segment info content
  const ClimbInfoContent = () => {
    return (
      <div>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <ClimbStats climbInfo={climbInfo} />
            </Grid>
            <Grid item xs={12}>
              <SegmentInfoMap latLngArray={climbProfile.latlng} show={true} />
            </Grid>
            <Grid item xs={12}>
              <GradientWidget
                label={climbInfo.label}
                distanceArray={climbProfile.distance}
                altitudeArray={climbProfile.altitude}
              />
            </Grid>
            <Grid item xs={12}>
              <PowerWidget
                totalElevationGain={climbInfo.elevationGain}
                climbCategory={5} // Needed to ensure the widget is shown
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  // Render
  if (!climbInfo || !climbProfile) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <ClimbInfoContent />
    </>
  );
}

export default ClimbInfo;
