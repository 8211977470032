import { Button, Container, TextField } from "@mui/material";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setGpxData } from "./slicers/gpxDataSlice";

function RouteAnalysisUploadFile({ close }) {
  const [filename, setFilename] = useState("");
  const [uploadFile, setUploadFile] = useState();
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const gpxFromFile = (file) => {
    dispatch(setGpxData(uploadFile));
  };

  const getFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFilename(file.name);
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = (e) => {
        setUploadFile(e.target.result);
      };
    }
  };

  const handleTextFieldClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = () => {
    gpxFromFile(uploadFile);
    close();
  };

  return (
    <Container className="p-0 my-2" align="left" disableGutters>
      <>
        <h2>Upload from device</h2>
        <p>Select a gpx file from your device</p>
        <div className="mb-3">
          <TextField
            fullWidth
            value={filename}
            onClick={handleTextFieldClick}
            readOnly
            placeholder="Upload File"
            variant="outlined"
            margin="dense"
            size="small"
          />
          <input
            ref={fileInputRef}
            accept=".gpx"
            onChange={getFile}
            type="file"
            hidden
          />
        </div>
        <Button variant="outlined" onClick={handleSubmit}>
          Submit
        </Button>
      </>
    </Container>
  );
}

export default RouteAnalysisUploadFile;
