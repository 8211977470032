import { Alert, Container, IconButton, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import {
  getStravaRouteGPX,
  getUserRoutes,
} from "../../../adapters/routes.axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { myRoutesCols } from "../../shared/tables/columns";
import { InputMUI, Loading } from "../../shared/mui.component";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import { useIsAuth } from "../../../adapters/users.axios";
import { setGpxData } from "./slicers/gpxDataSlice";
import { useDispatch } from "react-redux";
import StravaConnectButton from "../../shared/stravaButton.component";

function RouteAnalysisUploadMyRoutes({ close, error: setErrorLoad }) {
  const [myRoutes, setMyRoutes] = useState([]);
  const [loadingRoutes, setLoadingRoutes] = useState(false);
  const [columnWidth, setColumnWidth] = useState(undefined);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const divRef = useRef();
  const gridRef = useRef();

  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  useEffect(() => {
    if (isAuth) {
      const myLocalRoutes = localStorage.getItem("myRoutes");
      if (myLocalRoutes !== null && myLocalRoutes !== "undefined") {
        setMyRoutes(JSON.parse(myLocalRoutes));
      }
    }
  }, [isAuth]);

  const defaultColDef = {
    width: columnWidth,
    cellStyle: () => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }),
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const findRoutes = async () => {
    // Get or update user's routes from Strava and store in local storage if authenticated
    if (isAuth) {
      setLoadingRoutes(true);
      const response = await getUserRoutes();
      if (response.status === 200) {
        setMyRoutes(response.data);
        localStorage.setItem("myRoutes", JSON.stringify(response.data));
      } else {
        setError(response.data?.message || response.statusText);
      }
      setLoadingRoutes(false);
    }
  };

  const gpxFromStrava = async (id) => {
    const response = await getStravaRouteGPX(id);
    if (response.status === 200) {
      dispatch(setGpxData(response.data));
    } else {
      setErrorLoad("Could not load route.");
      console.log(response);
    }
  };

  const hanldeClick = (e) => {
    gpxFromStrava(e);
    close();
  };

  const MyRoutesList = () => {
    if (myRoutes.length > 0) {
      return (
        <>
          <Container
            className="ag-theme-alpine p-0 my-2"
            align="left"
            ref={divRef}
            disableGutters
            sx={{ height: "100%", width: "100%" }}
          >
            <InputMUI
              label="Search in routes"
              id="component-simple"
              onChange={(e) => onFilterTextBoxChanged(e)}
              InputProps={{
                endAdornment: [<SearchIcon key="search" />],
              }}
            />
            <AgGridReact
              ref={gridRef}
              domLayout="autoHeight"
              rowData={myRoutes}
              rowHeight={60}
              columnDefs={myRoutesCols}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              onRowClicked={(e) => {
                hanldeClick(e.data.id);
              }}
              pagination={true}
              paginationPageSize={8}
              onFirstDataRendered={() => {
                setColumnWidth(divRef.current.offsetWidth);
              }}
            />
          </Container>
        </>
      );
    }
  };

  if (isAuth === true) {
    return (
      <>
        <Typography
          component="div"
          variant="h5"
          sx={{ display: "flex", alignItems: "center" }}
        >
          My Strava routes
          <IconButton
            onClick={() => findRoutes()}
            sx={{ marginRight: "0.5rem" }}
          >
            <RefreshIcon disabled={loadingRoutes} />
          </IconButton>
        </Typography>
        <p>Click on a route to start analyzing</p>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {loadingRoutes ? <Loading /> : <MyRoutesList />}
      </>
    );
  } else {
    return (
      <>
        <Typography variant="h5">My Strava routes</Typography>
        <p>Log in with Strava to see your routes</p>
        <StravaConnectButton />
      </>
    );
  }
}

export default RouteAnalysisUploadMyRoutes;
