import axios from "./axios_interceptor";

const catchError = (err) => {
  if (err.message) {
    return err.message;
  }
};

export async function getRouteFromDB(id) {
  try {
    const result = await axios.get(`/api/routes/profile/${id}`, {
      withCredentials: true,
    });
    return result.data;
  } catch (err) {
    console.log(err);
    let msg = catchError(err);
    if (err.response.data.error) {
      msg = err.response.data.error;
    }
    return {
      status: false,
      error: msg,
    };
  }
}

export async function getStravaRouteGPX(id) {
  try {
    return await axios.get(`/strava/route/${id}`);
  } catch (err) {
    return err.response;
  }
}

export async function getRideWithGPSRouteGPX(id) {
  try {
    return await axios.get(`/rwgps/route/${id}`);
  } catch (err) {
    return err.response;
  }
}

export async function getUserRoutes() {
  try {
    return await axios.get("/strava/my_routes");
  } catch (err) {
    return err.response;
  }
}
