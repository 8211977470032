import React, { useContext, useState } from "react";

import { Slider } from "@mui/material";
import { SegmentSearchContext } from "../../../../contexts/SearchContext";

function DistanceSlider() {
  const { searchCond, setSearchCond } = useContext(SegmentSearchContext);

  const [range, setRange] = useState([0, 21]);

  const handleChange = (value) => {
    setRange(value);
    const lowBound = value[0] === 0 ? null : value[0] * 1000;
    const uppBound = value[1] === 21 ? null : value[1] * 1000;
    setSearchCond({
      ...searchCond,
      distance: { lowBound: lowBound, uppBound: uppBound },
    });
  };

  const marks = [
    { value: 0, label: "0km" },
    { value: 10, label: "10km" },
    { value: 20, label: "20km" },
    { value: 21, label: "..." },
  ];

  const rangeText = () => {
    const lowRange = range[0];
    const highRange = range[1] === 21 ? "∞" : range[1];
    return `[${lowRange}km, ${highRange}km]`;
  };

  return (
    <div>
      Distance range {rangeText()}
      <div className="mx-2">
        <Slider
          getAriaLabel={() => "Distance range"}
          value={range}
          onChange={(e, v) => handleChange(v)}
          valueLabelDisplay="auto"
          marks={marks}
          min={0}
          max={21}
        />
      </div>
    </div>
  );
}

export default DistanceSlider;
