import { Button, Container } from "@mui/material";
import React, { useRef } from "react";
import { getRideWithGPSRouteGPX } from "../../../adapters/routes.axios";
import { InputMUI } from "../../shared/mui.component";
import { useDispatch } from "react-redux";
import { setGpxData } from "./slicers/gpxDataSlice";

function RouteAnalysisUploadRWGPS({ close, error }) {
  const link = useRef("");

  const dispatch = useDispatch();

  const getGpx = async (id) => {
    const response = await getRideWithGPSRouteGPX(id);
    if (response.status === 200) {
      dispatch(setGpxData(response.data));
    } else {
      error("Could not load route.");
      console.log(response);
    }
  };

  // Get gpx file and set value
  const handleSubmit = () => {
    const id = link.current.value.split("/").pop();
    getGpx(id);
    close();
  };

  return (
    <Container className="p-0 my-2" align="left" disableGutters>
      <>
        <h2>Get RideWithGPS route</h2>
        <p>Enter the RideWithGPS route URL or ID</p>
        <div className="mb-3">
          <InputMUI
            label={"RideWithGPS link or route ID (public routes only)"}
            placeholder={"https://ridewithgps.com/routes/30594099 or 30594099"}
            ref={link}
          />
        </div>
        <Button variant="outlined" onClick={() => handleSubmit()}>
          Submit
        </Button>
      </>
    </Container>
  );
}

export default RouteAnalysisUploadRWGPS;
