import { Button, Container } from "@mui/material";
import React, { useRef } from "react";
import { getStravaRouteGPX } from "../../../adapters/routes.axios";
import { InputMUI } from "../../shared/mui.component";
import { useQuery } from "@tanstack/react-query";
import { useIsAuth } from "../../../adapters/users.axios";
import { useDispatch } from "react-redux";
import { setGpxData } from "./slicers/gpxDataSlice";
import StravaConnectButton from "../../shared/stravaButton.component";

function RouteAnalysisUploadStrava({ close, error }) {
  const link = useRef("");

  const dispatch = useDispatch();

  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  const gpxFromStrava = async (id) => {
    const response = await getStravaRouteGPX(id);
    if (response.status === 200) {
      dispatch(setGpxData(response.data));
    } else {
      dispatch(setGpxData(null));
      error("Could not load route.");
      console.log(response);
    }
  };

  const StravaUpload = () => {
    const label = isAuth
      ? "Strava link or route ID"
      : "Connect with Strava to use this feature";
    return (
      <InputMUI
        label={label}
        placeholder={"https://www.strava.com/routes/12345 or 12345"}
        ref={link}
        disabled={!isAuth}
      />
    );
  };

  // Get gpx file and set value
  const handleSubmit = () => {
    const id = link.current.value.split("/").pop();
    gpxFromStrava(id);
    close();
  };

  if (isAuth === true) {
    return (
      <Container className="p-0 my-2" align="left" disableGutters>
        <>
          <h2>Get Strava route</h2>
          <p>Enter the Strava route URL or ID</p>
          <div className="mb-3">
            <StravaUpload />
          </div>
          <Button variant="outlined" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </>
      </Container>
    );
  } else {
    return (
      <>
        <h2>Get Strava route</h2>
        <p>Log in with Strava to use this feature</p>
        <StravaConnectButton />
      </>
    );
  }
}

export default RouteAnalysisUploadStrava;
