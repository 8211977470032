import React from "react";
import { Card, Container, Grid, Typography } from "@mui/material";

function ClimbStats({ climbInfo }) {
  const getCardMUI = (title, value) => {
    return (
      <Card style={{ border: "none", boxShadow: "none" }}>
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
      </Card>
    );
  };

  const getStats = () => {
    const statsArr = [];
    const itemsSelected = [
      { Distance: `${(climbInfo.distance / 1000).toFixed(1)}km` },
      { "Starts at": `${(climbInfo.startsAt / 1000).toFixed(1)}km` },
      { "Ends at": `${(climbInfo.endsAt / 1000).toFixed(1)}km` },
      { "Avg Grade": `${(climbInfo.gradient * 100).toFixed(1)}%` },
      { "Elev Diff": `${(climbInfo.elevationGain / 1).toFixed(0)}m` },
      { "Climb score": (climbInfo.score || 0).toFixed(1) },
    ];
    // Loop through array to generate cards and add them to output array
    itemsSelected.forEach((obj, index) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      statsArr.push(
        <Grid item xs={4} key={index}>
          {getCardMUI(key, value)}
        </Grid>
      );
    });

    return statsArr;
  };

  return (
    <Container sx={{ maxWidth: "800px" }}>
      <Grid container columnSpacing={2} style={{ textAlign: "left" }}>
        {getStats()}
      </Grid>
    </Container>
  );
}

export default ClimbStats;
