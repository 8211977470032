import L from "leaflet";

const generatePulsatingMarker = function (radius, color) {
  const cssStyle = `
    width: ${radius}px;
    height: ${radius}px;
    background: ${color};
    color: ${color};
    box-shadow: 0 0 0 ${color};
    display: block;
    border-radius: 50%;
    cursor: pointer;
    animation: pulse 2s infinite;
  `;
  return L.divIcon({
    html: `<span style="${cssStyle}" class="pulse"/>`,
    // empty class name to prevent the default leaflet-div-icon to apply
    className: "",
  });
};

export const pulsatingIconBlack = generatePulsatingMarker(10, "black");
export const myLocationIcon = generatePulsatingMarker(10, "blue");

export const iconStyles = {
  "&.Mui-selected": {
    backgroundColor: "white",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "white",
  },
  "&:not(.Mui-selected)": {
    backgroundColor: "lightgrey",
    opacity: 0.5,
  },
};
