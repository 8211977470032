import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { AlertTimeout } from "../../../shared/mui.component";

export default function MoreMenu({ stravaID }) {
  const [message, setMessage] = useState();
  const [variant, setVariant] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleStrava = () => {
    setAnchorEl(null);
    window.open(
      `https://www.strava.com/segments/${stravaID}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleReport = async () => {
    // TODO: reimplement this
    // setAnchorEl(null);
    // const result = await reportSegment({
    //   id: stravaID,
    //   message: "reported by user",
    // });
    // if (result.status === 200) {
    //   setMessage(`Segment ${stravaID} reported`);
    //   setVariant("success");
    // } else {
    //   setMessage("Something went wrong");
    //   setVariant("error");
    // }
  };

  return (
    <div>
      <AlertTimeout message={message} variant={variant} />
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleStrava}>See on Strava</MenuItem>
        <MenuItem onClick={handleReport}>Report an issue</MenuItem>
      </Menu>
    </div>
  );
}
