import React, { useEffect, useRef, useState } from "react";
import { addMessage } from "../../../adapters/feedback.axios";
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Modal,
  Typography,
} from "@mui/material";
import { InputMUI } from "../../shared/mui.component";
import { useQuery } from "@tanstack/react-query";
import { useGetUser, useIsAuth } from "../../../adapters/users.axios";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "400px",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 12,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
};

function ContactModal({ show, close, hide }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const nameRef = useRef();
  const emailRef = useRef();
  const msgRef = useRef();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Get strava ID if available
  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });
  const { data: athleteInfo } = useQuery({
    queryKey: ["user", "info"],
    queryFn: useGetUser,
    enabled: isAuth === true,
  });

  async function onClick() {
    setError("");
    const data = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      message: msgRef.current.value,
      user_uuid: athleteInfo ? athleteInfo.user_uuid : null,
    };
    if (data.name && data.email && data.message) {
      const response = await addMessage(data);
      if (response.status === 200) {
        setSuccess("Thanks for your message");
      } else {
        setError("Something went wrong, please try again");
      }
    } else {
      setError("Please fill all the mandatory fields");
    }
  }

  const MyButton = () => {
    if (close && success) {
      return (
        <Button
          className="w-100 mt-2"
          variant="outlined"
          onClick={() => close()}
        >
          Close
        </Button>
      );
    }
    return (
      <Button className="w-100" variant="outlined" onClick={() => onClick()}>
        Submit
      </Button>
    );
  };

  const ContactForm = () => {
    return (
      <>
        <h2 className="text-center mb-4">Feedback</h2>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <InputMUI
          label="Name"
          inputRef={nameRef}
          placeholder="Freddie Mercury"
        />
        <InputMUI
          label="Email"
          type="email"
          inputRef={emailRef}
          placeholder="freddie@queen.com"
        />
        <InputMUI
          label="Message"
          inputRef={msgRef}
          placeholder="I want to ride my bicyle 🎶"
          multiline={true}
        />
        <MyButton />
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="overline"
            style={{ cursor: "pointer" }}
            className="mt-2"
            onClick={hide}
            sx={{ color: "secondary.main" }}
          >
            Hide feedback button
          </Typography>
        </div>
      </>
    );
  };

  return (
    <Modal open={show} onClose={close}>
      <Box sx={modalStyle}>
        <ContactForm />
      </Box>
    </Modal>
  );
}

export default ContactModal;
