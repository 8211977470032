import React from "react";
import { Button, Modal } from "react-bootstrap";

function ErrorMessage({ show, setShow, message }) {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>ERROR</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="danger"
          onClick={(e) => {
            setShow(false);
          }}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ErrorMessage;
