import React, { useEffect, useState, forwardRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
  Container,
  Paper,
  Snackbar,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { grey } from "@mui/material/colors";

export const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export function AccordionMUI({ title, body, show = false, setShow = null }) {
  // Fold and unfold options Accordion
  const [expandOptions, setExpandOptions] = useState(show);
  const handleExpand = (panel) => (event, newExpanded) => {
    setExpandOptions(newExpanded ? panel : false);
    if (setShow) {
      setShow(newExpanded ? panel : false);
    }
  };

  useEffect(() => {
    setExpandOptions(show);
  }, [show]);

  return (
    <Accordion expanded={expandOptions} onChange={handleExpand(true)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="options-content"
        id="options-header"
      >
        <Typography>
          <strong>{title}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
}

function InputMUITemp({
  label,
  inputRef,
  placeholder,
  type = "text",
  defaultValue = null,
  multiline = false,
  id = label,
  onChange = null,
  onKeyDown = null,
  InputProps = null,
  inputProps = {},
  ...props
}) {
  return (
    <TextField
      label={label}
      type={type}
      id={id}
      inputRef={inputRef}
      placeholder={placeholder}
      multiline={multiline}
      defaultValue={defaultValue}
      onKeyDown={onKeyDown}
      onChange={onChange}
      InputProps={InputProps}
      inputProps={inputProps}
      variant="outlined"
      fullWidth
      margin="dense"
      size="small"
      {...props}
    />
  );
}

export const InputMUI = forwardRef((props, ref) => {
  return <InputMUITemp inputRef={ref} {...props} />;
});

export function PaperCard({ title, subTitle, children, ...props }) {
  return (
    <Paper
      elevation={5}
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 2,
        ...props,
      }}
    >
      <div className="m-3" align="left">
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1">{subTitle}</Typography>
        {children}
      </div>
    </Paper>
  );
}

export function PageContent({ children }) {
  return <Container className="my-3">{children}</Container>;
}

export function AlertTimeout({ message, variant }) {
  const [showAlert, setShowAlert] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  useEffect(() => {
    if (message && variant) {
      setShowAlert(true);
    }
  }, [message, variant]);

  return (
    <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={variant} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export function Loading() {
  return <CircularProgress color="inherit" />;
}
