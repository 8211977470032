import axios from "./axios_interceptor";

export async function useLogout() {
  const result = await axios.delete("/auth/logout");
  return result.data;
}

export async function useGetMySegments() {
  const segments = await axios.get("/strava/starred_segments");
  return segments.data;
}

export async function useIsAuth() {
  // Checks whether user is authenticated
  try {
    const response = await axios.get("/auth/is_auth");
    return response.data;
  } catch (error) {
    return false;
  }
}

export async function useIsAdmin() {
  // Checks whether user is admin
  try {
    const response = await axios.get("/auth/is_admin");
    return response.data;
  } catch (error) {
    return false;
  }
}

export async function useGetUser() {
  // Gets user's Strava information
  const user = await axios.get("/user/info");
  return user.data;
}

export async function useGetAllUsers() {
  // Gets all users
  const users = await axios.get("/user/all");
  return users.data;
}
