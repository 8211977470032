import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useIsAdmin, useIsAuth } from "../adapters/users.axios";

const PrivateRoute = () => {
  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });
  if (isAuth === undefined) {
    return <div>Loading...</div>;
  }
  if (isAuth) {
    return <Outlet />;
  } else {
    return <Navigate to="/noaccess" />;
  }
};

const AdminRoute = () => {
  const { data: isAdmin } = useQuery({
    queryKey: ["auth", "isAdmin"],
    queryFn: useIsAdmin,
  });
  if (isAdmin === undefined) {
    return <div>Loading...</div>;
  }
  if (isAdmin) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export { PrivateRoute, AdminRoute };
