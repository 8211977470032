import * as d3 from "d3";

export const getBase = (
  svgRef,
  distance,
  elevation,
  scaleX,
  scaleY,
  handleMouseMove,
  margin,
  svgWidth,
  svgHeight
) => {
  // Check whether scaleX and scaleY are defined - this implicitly checks whether distance and elevation are defined
  if (!scaleX || !scaleY) return;

  // Reset SVG
  d3.select(svgRef.current).selectAll("*").remove();

  const width = svgWidth - margin.left - margin.right;
  const height = svgHeight - margin.top - margin.bottom;

  // Select the SVG element using the ref, and set up the initial transform
  const svg = d3
    .select(svgRef.current)
    .attr("width", svgWidth)
    .attr("height", svgHeight)
    .on("mousemove", handleMouseMove)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  // Define and render the axes
  const tickCountX = Math.max(2, Math.floor(width / 100)); // Example calculation
  const tickCountY = Math.max(2, Math.floor(height / 50)); // Example calculation

  const xAxis = d3
    .axisBottom(scaleX)
    .ticks(tickCountX)
    .tickFormat((d) => `${d / 1000}km`);
  const yAxis = d3
    .axisLeft(scaleY)
    .ticks(tickCountY)
    .tickFormat((d) => `${d}m`);

  svg.append("g").attr("transform", `translate(0,${height})`).call(xAxis);
  svg.append("g").call(yAxis);

  // Line function for the elevation path
  const line = d3
    .line()
    .x((d, i) => scaleX(distance[i]))
    .y((d, i) => scaleY(elevation[i]));

  const area = d3
    .area()
    .x((d, i) => scaleX(distance[i]))
    .y0(height) // This is the bottom of the SVG where the x-axis is
    .y1((d, i) => scaleY(elevation[i])); // This is the top line of the area

  // Append the filled area to the SVG
  svg
    .append("path")
    .datum(elevation) // Bind the data to your path
    .attr("class", "area") // You can use this class to style the area with CSS
    .attr("fill", "lightgrey") // Set the fill color for the area
    .attr("d", area); // Use the area function to draw

  // Optionally append the line path if you want the line on top of the filled area
  svg
    .append("path")
    .datum(elevation)
    .attr("fill", "none")
    .attr("stroke", "black")
    .attr("d", line);
};

export const getTitle = (svgRef, stats, svgWidth, margin) => {
  // Get the svg element
  if (!stats || !svgRef.current) return;
  const svg = d3.select(svgRef.current);
  svg.selectAll(".title").remove();
  const offsetY = 5;

  // Append the title to the SVG
  svg
    .append("text")
    .attr("x", svgWidth / 2) // Center the title
    .attr("y", offsetY) // Position the title at the top margin
    .attr("class", "title")
    .attr("text-anchor", "middle") // Center the text horizontally
    .attr("dominant-baseline", "hanging") // Ensure the text hangs from the y position
    .style("font-size", "16px") // Set the font size
    .style("font-family", "sans-serif") // Set the font family
    .style("font-weight", "bold")
    .text(stats.name); // Replace with your actual title

  // Add subtitle with distance and elevation gain
  svg
    .append("text")
    .attr("x", svgWidth / 2) // Center the title
    .attr("y", offsetY + 15) // Position the title at the top margin
    .attr("class", "title")
    .attr("text-anchor", "middle") // Center the text horizontally
    .attr("dominant-baseline", "hanging") // Ensure the text hangs from the y position
    .style("font-size", "12px") // Set the font size
    .style("font-family", "sans-serif") // Set the font family
    .text(
      `${(stats.distance / 1000).toFixed(1)} km, ${stats.elevation?.pos.toFixed(
        0
      )} m`
    ); // Replace with your actual title
};

export const getClimbs = (
  svgRef,
  climbs,
  distance,
  elevation,
  scaleX,
  scaleY,
  height
) => {
  // Get the svg element
  const svg = d3.select(svgRef.current).select("g");

  // Delete all items with class "climb"
  svg.selectAll(".climb").remove();

  if (!distance || distance.length === 0 || !svgRef.current) return;
  if (climbs) {
    climbs.forEach((climb) => {
      const climbStart = climb.start;
      const climbEnd = climb.end;
      const climbDistance = distance.slice(climbStart, climbEnd);
      const climbElevation = elevation.slice(climbStart, climbEnd);

      const climbArea = d3
        .area()
        .x((d, i) => scaleX(climbDistance[i]))
        .y0(height) // This is the bottom of the SVG where the x-axis is
        .y1((d, i) => scaleY(climbElevation[i])); // This is the top line of the area

      // Append the filled area to the SVG
      svg
        .append("path")
        .datum(climbElevation) // Bind the data to your path
        .attr("fill", "yellow") // Set the fill color for the area
        .attr("class", "climb")
        .attr("d", climbArea); // Use the area function to draw
    });
  }
};

export const getPosition = (
  svgRef,
  position,
  distance,
  elevation,
  scaleX,
  width,
  height
) => {
  // Constants for the tooltip
  const tooltipWidth = 100;
  const tooltipHeight = 35;
  const tooltipOffset = 10;
  const rightEdgeBuffer = 10; // Buffer to the right edge of the SVG

  // Remove the position line and tooltip
  d3.select(svgRef.current).select("g").selectAll(".position").remove();
  d3.select(svgRef.current).select("g").selectAll(".position-tooltip").remove();

  if (
    position !== null &&
    position >= 0 &&
    position < distance.length &&
    svgRef.current
  ) {
    // Get the svg element
    const svg = d3.select(svgRef.current).select("g");

    // Delete all items with class "position"
    svg.selectAll(".position").remove();

    // Get the position for the vertical and horizontal lines
    const positionX = scaleX(distance[position]);

    // Draw the vertical line
    svg
      .append("line")
      .attr("x1", positionX)
      .attr("y1", 0.05 * height)
      .attr("x2", positionX)
      .attr("y2", height)
      .attr("stroke", "red") // Use a stroke color that stands out
      .attr("stroke-width", 2) // Adjust the stroke width as needed
      .attr("class", "position");

    // Determine if the tooltip should be to the left or right of the line
    const xPosition =
      positionX + tooltipOffset + tooltipWidth + rightEdgeBuffer > width
        ? positionX - tooltipOffset - tooltipWidth // Tooltip to the left of the line
        : positionX + tooltipOffset; // Tooltip to the right of the line

    // Group for the tooltip
    const tooltipGroup = svg
      .append("g")
      .attr("class", "position-tooltip")
      .style("pointer-events", "none") // Make sure the tooltip doesn't interfere with mouse events
      .attr(
        "transform",
        `translate(${xPosition},${tooltipHeight + 0.05 * height})`
      );

    // Draw the rectangle for the tooltip background
    tooltipGroup
      .append("rect")
      .attr("y", -tooltipHeight) // Offset the rectangle above the line
      .attr("width", tooltipWidth) // Set the width of the tooltip
      .attr("height", tooltipHeight) // Set the height of the tooltip
      .attr("fill", "white")
      .style("opacity", 0.85)
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Add text for the distance
    tooltipGroup
      .append("text")
      .attr("x", 5) // Offset the text to the right of the line
      .attr("y", -20) // Offset the text above the line
      .text(`Dist.: ${(distance[position] / 1000).toFixed(1)} km`)
      .style("font-size", "12px");
    // Change font size

    // Add text for the elevation
    tooltipGroup
      .append("text")
      .attr("x", 5) // Offset the text to the right of the line
      .attr("y", -5) // Position the text below the distance line
      .text(`Elev.: ${elevation[position].toFixed(0)} m`)
      .style("font-size", "12px");
  }
};

export const removePosition = (svgRef) => {
  // Remove the position line and tooltip
  d3.select(svgRef.current).select("g").selectAll(".position").remove();
  d3.select(svgRef.current).select("g").selectAll(".position-tooltip").remove();
};
