import React from "react";
import { Container, Navbar } from "react-bootstrap";

function MyFooter() {
  return (
    <Navbar bg="light" expand="lg" className="mainFooter">
      <Container className="align-self-center">
        <a
          className="align-items-center"
          href="https://www.buymeacoffee.com/roeld"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="em-1"
            src={"/bmc/bmc-full-logo-no-background.png"}
            alt="Buy me a coffee :)"
            title="Buy me a coffee"
            loading="lazy"
          />
        </a>
        <a
          className="align-items-center"
          href="https://www.strava.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="em-2"
            src={"/strava/api_logo_pwrdBy_strava_stack_light.png"}
            alt="Powered by Strava"
            title="Powered by Strava"
            loading="lazy"
          />
        </a>
      </Container>
    </Navbar>
  );
}

export default MyFooter;
