import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import ClimbInfo from "../../shared/climbInfo/climbInfo.component";
import { Carousel } from "react-responsive-carousel";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import useDeviceType from "../../hooks/screensize";

function ClimbStatsModal({ arrClimbs, showModal, setShowModal }) {
  const screenSize = useDeviceType();
  const fullScreen = screenSize <= 1;

  const ClimbAnalysisModal = () => {
    return (
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        fullScreen={fullScreen}
        maxWidth="md"
      >
        <DialogContent sx={{ padding: 0 }}>
          <ClimbAnalysisCarousel />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ClimbAnalysisCarousel = () => {
    const buttonStyle = {
      position: fullScreen ? "fixed" : "absolute",
      top: "5px",
      zIndex: "3000",
    };

    return (
      <Carousel
        centerMode={true}
        showThumbs={false}
        showIndicators={false}
        centerSlidePercentage={100}
        swipeable={false}
        infiniteLoop={true}
        style={{ position: "relative" }}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <IconButton
              onClick={onClickHandler}
              title={label}
              style={{
                ...buttonStyle,
                left: fullScreen ? "40px" : "10px",
              }}
            >
              <ArrowCircleLeftIcon />
            </IconButton>
          )
        }
        renderArrowNext={(onClickHandler, nasNext, label) =>
          nasNext && (
            <IconButton
              onClick={onClickHandler}
              title={label}
              style={{
                ...buttonStyle,
                right: fullScreen ? "40px" : "10px",
              }}
            >
              <ArrowCircleRightIcon />
            </IconButton>
          )
        }
      >
        {arrClimbs.map((item) => (
          <div className="m-3" key={item.climbInfo.label}>
            <h4>{item.climbInfo.label}</h4>
            <ClimbInfo
              climbInfo={item.climbInfo}
              climbProfile={item.climbProfile}
            />
          </div>
        ))}
      </Carousel>
    );
  };

  // Return component
  if (arrClimbs && arrClimbs.length > 0) {
    return (
      <>
        <ClimbAnalysisModal />
      </>
    );
  }
}

export default ClimbStatsModal;
