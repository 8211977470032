import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GradientSlider from "./subComponents/optionsGradient.component";
import DistanceSlider from "./subComponents/optionsDistance.component";
import ClimbCatSlider from "./subComponents/optionsClimbCat.component";
// import ResetSliders from "./reset.component";
import NumberSegments from "./subComponents/optionsNumSegments.component";

function ExploreOptions() {
  return (
    <div className="my-2">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="options-content"
          id="options-header"
        >
          <Typography>
            <strong>Options</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GradientSlider />
          <DistanceSlider />
          <ClimbCatSlider />
          <NumberSegments />

          {/* <ResetSliders /> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ExploreOptions;
