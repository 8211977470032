import { Link } from "@mui/material";
import React from "react";

function StravaConnectButton({ light = false, text = false, url = false }) {
  let baseFwd = `${window.location.protocol}//${window.location.host}`;
  if (window.location.host.includes("3000")) {
    baseFwd = `${window.location.protocol}//${window.location.host.replace(
      "3000",
      "8000"
    )}`;
  }
  let image = "/strava/connect_strava_orange.png";
  if (light === true) {
    image = "/strava/connect_strava_light.png";
  }
  if (url) {
    return `https://www.strava.com/oauth/authorize?client_id=51057&redirect_uri=${baseFwd}/api/auth/login_strava&response_type=code&scope=read_all,activity:read_all,activity:write`;
  }

  if (text) {
    return (
      <Link
        href={`https://www.strava.com/oauth/authorize?client_id=51057&redirect_uri=${baseFwd}/api/auth/login_strava&response_type=code&scope=read_all,activity:read_all,activity:write`}
        underline="none"
        style={{
          color: "black",
          textDecoration: "none",
        }}
      >
        Connect with Strava
      </Link>
    );
  }
  return (
    <>
      <a
        className="align-items-center"
        href={`https://www.strava.com/oauth/authorize?client_id=51057&redirect_uri=${baseFwd}/api/auth/login_strava&response_type=code&scope=read_all,activity:read_all,activity:write`}
      >
        <img src={image} height="48" alt="" />
      </a>
    </>
  );
}
export default StravaConnectButton;
