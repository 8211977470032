import { useContext, useEffect } from "react";

import { useMapEvents } from "react-leaflet";
import { SegmentSearchContext } from "../../../../contexts/SearchContext";

function MapBase({ mapRef }) {
  // Handles the base map and sets states based on changes in map view
  const { setMapCenter, setMapZoom, setMapBounds } =
    useContext(SegmentSearchContext);

  const handleMapMoveEnd = () => {
    const newCenter = mapRef.current?.getCenter();

    localStorage.setItem(
      "explore",
      JSON.stringify([newCenter.lat, newCenter.lng])
    );
    setMapCenter([newCenter.lat, newCenter.lng]);
    const newZoom = mapRef.current?.getZoom();
    setMapZoom(newZoom);
    const newBounds = mapRef.current.getBounds();
    setMapBounds(newBounds);
  };

  // Set map parameters at first render
  useEffect(() => {
    handleMapMoveEnd();
  }, []);

  // Register event listeners on the map
  useMapEvents({
    moveend: handleMapMoveEnd,
  });
}

export default MapBase;
