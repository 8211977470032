// Returns climb score following ClimbByBike formula
export const calcClimbScore = (altStart, altEnd, distance) => {
  const elevDiff = altEnd - altStart;
  let result =
    ((elevDiff * 100) / distance) * 2 +
    (elevDiff * elevDiff) / distance +
    distance / 1000;
  if (altEnd > 1000) {
    result = result + (altEnd - 1000) / 100;
  }
  return result;
};

export const getTickerScale = (altDiff, type = "elev", precision = 6) => {
  let arrOps = [500, 1000, 2000, 5000, 10000, 20000, 30000, 50000, 100000];
  if (type === "elev") {
    arrOps = [1, 2, 5, 10, 20, 30, 40, 50, 75, 100, 200, 300, 400, 500];
  }
  let scale = 1;
  let skip = false;
  arrOps.forEach((item) => {
    if (skip) {
      return;
    }
    if (precision * item >= altDiff) {
      scale = item;
      skip = true;
    }
  });
  return scale;
};

// Returns font size as percentage of default
export const getFontSize = ({ graphWidth, maxSize = 150, factor = 1 }) => {
  const minSize = 50;
  const widthUpper = 600;
  const widthLower = 200;
  let fontSize = maxSize;
  if (graphWidth < widthUpper && graphWidth > widthLower) {
    fontSize =
      minSize +
      ((graphWidth - widthLower) / (widthUpper - widthLower)) *
        (maxSize - minSize);
  }
  return fontSize * factor;
};
