export const graphHeight = 300;
export const graphWidth = 700;
export const graphStartX = 40;
export const graphStartY = 30;
export const canvasHeight = graphHeight + 2 * graphStartY;
export const canvasWidth = graphWidth + graphStartX;
export const svgHeight = graphHeight + 2 * graphStartY;
export const svgWidth = graphWidth + 2 * graphStartX;
export const bottomY = graphStartY + graphHeight; // position of x-axis
export const offsetGradient = -20; // offset of gradient number relative to x-axis
export const offsetScaleY = 20; // offset of gradient number relative to x-axis
export const offsetScaleX = 0; // offset of gradient number relative to y-axis
export const offsetY = 15; // lowest point on y-axis
export const offsetX = 5; // offset for altitude indication
export const posDistY = bottomY + offsetScaleY; // offset of gradient number relative to x-axis

// x-axis and y-axis
export const xAxis = {
  x1: graphStartX,
  x2: graphStartX + graphWidth,
  y1: graphStartY + graphHeight,
  y2: graphStartY + graphHeight,
};
export const yAxis = {
  x1: graphStartX,
  x2: graphStartX,
  y1: graphStartY,
  y2: graphStartY + graphHeight,
};
