import { Helmet as HelmetComponent } from "react-helmet-async";
import pages from "../../data/pages.json";

function Helmet({ page = null, title = null, description = null }) {
  // Define title
  if (!title && pages?.[page]?.title) {
    title = pages[page].title;
  } else if (!title) {
    title = "PrepMyRide | The start of all your rides";
  }
  // Define description
  if (!description && pages?.[page]?.description) {
    description = pages[page].description;
  } else if (!description) {
    description =
      "Detailed route analyses, dynamic climb profiles, power insights to attack Strava KOMs, and more.";
  }

  return (
    <HelmetComponent>
      <title>{title}</title>
      <meta name="description" content={description} />
    </HelmetComponent>
  );
}

export default Helmet;
