import React from "react";
import { useParams } from "react-router-dom";
import SegmentStats from "./stats/stats.component";
import GradientWidget from "../../shared/widgets/graph/graph.component";
import PowerWidget from "../../shared/widgets/power/power.component";
import WeatherWidget from "../../shared/widgets/weather/weather.component";
import SegmentLeaderboard from "./leaderboard/leaderboard.component";
import Hunter from "../../shared/widgets/hunter/hunter.component";
import {
  useGetSegmentInfo,
  useGetSegmentProfile,
} from "../../../adapters/segments.axios";
import { Alert, Container, Grid } from "@mui/material";
import Title from "./titlebar/titleBar.component";
import { Loading, PageContent } from "../../shared/mui.component";
import SegmentInfoMap from "../../shared/widgets/map/map.component";
import Helmet from "../../shared/helmet";
import StravaConnectButton from "../../shared/stravaButton.component";
import { useQuery } from "@tanstack/react-query";
import { useIsAuth } from "../../../adapters/users.axios";

function SegmentInfo() {
  const { segmentId } = useParams();

  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  const {
    data: segmentInfo,
    error: segmentInfoError,
    isFetching: segmentInfoFetching,
  } = useQuery({
    queryKey: ["segment", segmentId, "info"],
    queryFn: useGetSegmentInfo,
    enabled: isAuth === true,
  });

  const {
    data: segmentProfile,
    error: segmentProfileError,
    isFetching: segmentProfileFetching,
  } = useQuery({
    queryKey: ["segment", segmentId, "profile"],
    queryFn: useGetSegmentProfile,
    enabled: isAuth === true,
  });

  // Segment not found
  const SegmentNotFound = () => {
    return (
      <>
        <br />
        <Container align="center" style={{ maxWidth: "1200px" }} disableGutters>
          <Alert severity="error">Segment not found</Alert>
        </Container>
      </>
    );
  };

  // Segment info content
  const SegmentInfoContent = () => {
    return (
      <div>
        <Title input={segmentInfo} />
        <PageContent>
          <Grid container columnSpacing={2}>
            {/* Left column on large screens */}
            <Grid item xs={12} md={8}>
              <Grid item xs={12}>
                <SegmentStats input={segmentInfo} />
              </Grid>
              <Grid item xs={12}>
                <SegmentInfoMap
                  latLngArray={segmentProfile.coordinates}
                  show={true}
                />
              </Grid>
              <Grid item xs={12}>
                <GradientWidget
                  label={segmentInfo.name}
                  distanceArray={segmentProfile.distance}
                  altitudeArray={segmentProfile.elevation}
                />
              </Grid>
            </Grid>
            {/* Right column on large screens */}
            <Grid item xs={12} md={4}>
              <Grid item xs={12}>
                <PowerWidget
                  totalElevationGain={segmentInfo?.total_elevation_gain}
                  climbCategory={segmentInfo?.climb_category}
                  kom={segmentInfo?.kom?.time}
                  qom={segmentInfo?.qom?.time}
                />
              </Grid>
              <Grid item xs={12}>
                <WeatherWidget latLng={segmentInfo.start_latlng} />
              </Grid>
              <Grid item xs={12}>
                <Hunter
                  distance={segmentInfo.distance}
                  averageGrade={segmentInfo.average_grade}
                  kom={segmentInfo.kom}
                  qom={segmentInfo.qom}
                  isHazardous={segmentInfo.hazardous}
                />
              </Grid>
            </Grid>
            {/* Bottom row */}
            <Grid item xs={12}>
              <SegmentLeaderboard id={segmentId} />
            </Grid>
          </Grid>
        </PageContent>
      </div>
    );
  };

  const ContentAuth = () => {
    return (
      <>
        <Helmet
          page="segments"
          title={`PrepMyRide | ${segmentInfo?.name} (${segmentId})`}
        />
        {segmentProfileFetching || segmentInfoFetching ? (
          <Loading />
        ) : segmentProfileError || segmentInfoError ? (
          <SegmentNotFound />
        ) : (
          <SegmentInfoContent />
        )}
      </>
    );
  };

  const ContentNoAuth = () => {
    return (
      <PageContent>
        <Helmet
          page="segments"
          title={`PrepMyRide | Segment info (${segmentId}) `}
        />
        <h1 align="left">Segment info</h1>
        <p align="left">
          You need to be connected with Strava to access all features on this
          page.
        </p>
        <StravaConnectButton />
        <br />
        <br />
        <SegmentLeaderboard id={segmentId} />
      </PageContent>
    );
  };

  // Render
  if (!isAuth && isAuth === undefined) {
    return <Loading />;
  } else if (!isAuth) {
    return <ContentNoAuth />;
  } else return <ContentAuth />;
}

export default SegmentInfo;
