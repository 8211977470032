import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PlaceIcon from "@mui/icons-material/Place";
import SubjectIcon from "@mui/icons-material/Subject";

import SideBar from "./sideBar.component.js";
import { useQuery } from "@tanstack/react-query";
import { useIsAuth } from "../../adapters/users.axios.js";

const MainMenu = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  const pagesLoggedIn = useMemo(
    () => [
      {
        header: "About",
        link: "/about",
        icon: <InfoIcon />,
      },
      {
        header: "My Segments",
        link: "/mysegments",
        icon: <SubjectIcon />,
      },
      {
        header: "Segment Explorer",
        link: "/explore",
        icon: <TravelExploreIcon />,
      },

      {
        header: "Route Analysis",
        link: "/routes/analysis",
        icon: <QueryStatsIcon />,
      },
      {
        header: "POI Finder",
        link: "/poi",
        icon: <PlaceIcon />,
      },
    ],
    []
  );

  const pagesLoggedOut = useMemo(
    () => [
      {
        header: "About",
        link: "/about",
        icon: <InfoIcon />,
      },
      {
        header: "Route Analysis",
        link: "/routes/analysis",
        icon: <QueryStatsIcon />,
      },
      {
        header: "POI Finder",
        link: "/poi",
        icon: <PlaceIcon />,
      },
    ],
    []
  );

  const [menuItems, setMenuItems] = useState([]);

  // Set menus and avatars based on status currentUser
  useEffect(() => {
    if (isAuth) {
      setMenuItems(pagesLoggedIn);
    } else if (isAuth === false) {
      setMenuItems(pagesLoggedOut);
    }
  }, [isAuth, pagesLoggedIn, pagesLoggedOut]);

  const FullMenu = () => {
    return (
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        {menuItems.map((page) => (
          <Button
            component={Link}
            to={page.link}
            key={page.header}
            sx={{ my: 2, color: "white", display: "block" }}
            style={{
              color: "black",
            }}
          >
            {page.header}
          </Button>
        ))}
      </Box>
    );
  };

  const CompactMenu = () => {
    return (
      <SideBar
        icon={<MenuIcon />}
        menuItems={menuItems}
        header="prepmyride.cc"
      />
    );
  };

  return <Box sx={{ flexGrow: 1 }}>{md ? <FullMenu /> : <CompactMenu />}</Box>;
};

export default MainMenu;
