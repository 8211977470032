import React from "react";
import { Grid } from "@mui/material";
import HomeExplore from "./subExplore.component";
import HomeFeatures from "./subFeatures.component";
import Helmet from "../../shared/helmet";

const HomePage = () => {
  return (
    <>
      <Helmet page="home" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HomeExplore />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <HomeFeatures />
        </Grid>
      </Grid>
    </>
  );
};

export default HomePage;
