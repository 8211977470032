import { useEffect, useState } from "react";

const useReadMarkdown = ({ path }) => {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    fetch(path)
      .then((response) => response.text())
      .then((data) => setMarkdownContent(data))
      .catch((error) => console.error(error));
  }, [path]);

  return markdownContent;
};

export default useReadMarkdown;
