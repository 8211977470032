import React from "react";
import { useGetWeather } from "../../../../adapters/segments.axios";
import { PaperCard } from "../../../shared/mui.component";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";

function WeatherWidget({ latLng }) {
  const { data, isFetching, error } = useQuery({
    queryKey: ["weather", latLng[0], latLng[1]],
    queryFn: useGetWeather,
    enabled: latLng !== undefined,
  });

  const weatherBlock = (time, icon, temp, key, description) => {
    // Single weather block
    return (
      <div className="weather-cell" align="center" key={key}>
        <h6>{time}</h6>
        <img
          className="em-3"
          src={`/weatherIcons/${icon}@2x.png`}
          alt="Weather icon"
          title={description}
        />
        <h3>{temp}°</h3>
      </div>
    );
  };

  const getWeatherBlocks = () => {
    // Function to get all weather blocks
    const arrBlocks = [];
    data.data.forEach((item, index) => {
      const time = item.dt_txt.substring(11, 13);
      const icon = item.icon;
      const description = item.description;
      const temp = item.temp.toFixed(0);
      if (index < 8 && index > 0) {
        arrBlocks.push(
          weatherBlock(time, icon, temp, item.dt_txt, description)
        );
      }
    });
    return arrBlocks;
  };

  const WeatherLoading = () => {
    return (
      <PaperCard title="Weather widget">
        <CircularProgress />
      </PaperCard>
    );
  };

  if (isFetching) {
    return <WeatherLoading />;
  }

  if (!isFetching && data && data.data.length > 0) {
    return (
      <PaperCard
        title="Weather widget"
        subTitle={
          <>
            {data.city}{" "}
            <span>
              <small>(</small>
              <a
                href={`https://openweathermap.org/city/${data.id}`}
                target="_BLANK"
                rel="noreferrer"
                color="grey"
              >
                <small>more</small>
              </a>
              <small>)</small>
            </span>
          </>
        }
      >
        <Row className="align-items-center">
          <Col>
            <h3>{data.data[0].temp.toFixed(0)}°</h3>
          </Col>
          <Col align="right">{data.data[0].description}</Col>
          <Col align="left">
            <img
              className="em-3"
              src={`/weatherIcons/${data.data[0].icon}@2x.png`}
              alt="Weather icon"
            />
          </Col>
        </Row>
        <hr className="narrow" />
        <div className="overflow">{getWeatherBlocks()}</div>
      </PaperCard>
    );
  }
  return <></>;
}

export default WeatherWidget;
