import { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import "font-awesome/css/font-awesome.min.css";
import { usePosition } from "../../hooks/position";
import { pulsatingIconBlack } from "./icons";
import L from "leaflet";
import { Button, ThemeProvider, createTheme } from "@mui/material";

export const CustomButton = ({ label, handleClick }) => {
  const map = useMap();
  const [showButton, setShowButton] = useState(true);
  // Check if map bounds have changed
  useEffect(() => {
    map.on("moveend", () => {
      setShowButton(true);
    });
  }, [map]);

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#fff",
        contrastText: "black",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        color="neutral"
        size="small"
        onClick={() => {
          setShowButton(false);
          handleClick();
        }}
        sx={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 800,
        }}
        disabled={!showButton}
      >
        {label}
      </Button>
    </ThemeProvider>
  );
};

// Component to show user location on map.
// Fly to user location on first load if flyOnLoad is true.
// Fly to user location on button click.
const Location = ({ flyOnLoad = true }) => {
  const map = useMap();
  const [position, setPosition] = useState(null);
  const { latitude, longitude } = usePosition(true, {
    enableHighAccuracy: true,
  });

  // Add button to fly to user location
  useEffect(() => {
    function handleClick() {
      map.flyTo(position, map.getZoom());
    }
    // Don't add button if map or position is not set
    if (!map || !position) return;
    const button = L.easyButton("fa-map-marker", handleClick);
    button.addTo(map);

    return () => {
      button.remove();
    };
  }, [map, position]);

  // Get user location and fly to it on first load
  useEffect(() => {
    if (flyOnLoad) {
      map.locate({
        setView: false,
      });
      map.on("locationfound", (event) => {
        setPosition(event.latlng);
        map.flyTo(event.latlng, 15); // Zoom in on user location
      });
    }
  }, [flyOnLoad, map]);

  // Update position if user moves
  useEffect(() => {
    if (latitude && longitude) {
      setPosition([latitude, longitude]);
    }
  }, [latitude, longitude]);

  return position ? (
    <Marker position={position} icon={pulsatingIconBlack}>
      <Popup>You are here</Popup>
    </Marker>
  ) : null;
};

const MapWrapper = ({
  mapRef,
  center = null,
  zoom = 12,
  bounds = null,
  showLocation = true,
  flyOnLoad = true,
  children,
}) => {
  // If no bounds or center is given, set default center
  if (!bounds && !center) {
    center = [45.094444, 6.069444];
  }
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      bounds={bounds}
      scrollWheelZoom={false}
      ref={mapRef}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
      {showLocation ? <Location flyOnLoad={flyOnLoad} /> : null}
    </MapContainer>
  );
};

export default MapWrapper;
