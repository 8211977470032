import React from "react";
import ScoreSlider from "./score.component";
import { IconButton, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import InfoIcon from "@mui/icons-material/Info";
import { PaperCard } from "../../mui.component";

function Hunter({ distance, averageGrade, kom, qom, hazardous: isHazardous }) {
  let content = "";
  let title = "KOM/QOM scores";

  const getAnalysis = (xom, xomType) => {
    const score = xom?.score;
    const accuracy = xom?.acc;

    if (xom !== null && score) {
      return (
        <>
          <span>
            {xomType} - {score}
            <Tooltip title={`Accuracy: ${accuracy}`}>
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
          <ScoreSlider score={score} />
        </>
      );
    } else {
      return (
        <>
          <span>
            {xomType} - Unavailable
            <Tooltip title={"Insufficient comparative data"}>
              <IconButton>
                <NotificationImportantIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
          <ScoreSlider hide={true} />
        </>
      );
    }
  };

  if (distance && averageGrade !== null) {
    // Only for segments with 0 or positive average grade
    if (averageGrade >= 0 && !isHazardous) {
      title = (
        <>
          KOM/QOM scores
          <Tooltip title="Score out of 100 - the higher the score, the more difficult it is to beat the record">
            <IconButton>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </>
      );
      content = (
        <>
          {getAnalysis(kom, "KOM")}
          {getAnalysis(qom, "QOM")}
        </>
      );
    }
    // Otherwise, don't return a score
    else {
      content = <>No scores available for this segment.</>;
    }
  }
  // While no segment info is available
  else {
    content = <>Loading...</>;
  }
  // Return component
  return <PaperCard title={title}>{content}</PaperCard>;
}

export default Hunter;
