import React, { useContext, useEffect, useState } from "react";

import { Alert, Snackbar } from "@mui/material";
import { SegmentSearchContext } from "../../../contexts/SearchContext";

function ExploreAlerts() {
  const { alertMessage } = useContext(SegmentSearchContext);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (Object.keys(alertMessage).length !== 0) {
      setShowAlert(true);
    }
  }, [alertMessage]);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };
  return (
    <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={(e, r) => handleClose(r)}
        severity={alertMessage.variant}
        sx={{ width: "100%" }}
      >
        {alertMessage.message}
      </Alert>
    </Snackbar>
  );
}

export default ExploreAlerts;
