import React from "react";
import { Col, Row } from "react-bootstrap";
import { Card, Typography } from "@mui/material";
import { convertToHHMMSS } from "../../../../utils/general";

function SegmentStats({ input }) {
  const getCardMUI = (title, value) => {
    return (
      <Card style={{ border: "none", boxShadow: "none" }}>
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
      </Card>
    );
  };

  const getStats = (stats) => {
    const statsArr = [];
    const itemsSelected = [
      { Distance: `${(stats.distance / 1000).toFixed(2)}km` },
      { "Avg Grade": `${stats.average_grade}%` },
      { "Elev Diff": `${(stats.total_elevation_gain / 1).toFixed(0)}m` },
      // { "Climb Cat": stats.climb_category },

      { KOM: convertToHHMMSS(stats.kom?.time, true) },
      { QOM: convertToHHMMSS(stats.qom?.time, true) },
      { "Climb score": (stats?.score || 0).toFixed(1) },
    ];
    // Loop through array to generate cards and add them to output array
    itemsSelected.forEach((obj, index) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      statsArr.push(<Col key={index}>{getCardMUI(key, value)}</Col>);
    });

    return statsArr;
  };

  if (input) {
    return (
      <Row xs={3} md={6} className="g-4">
        {getStats(input)}
      </Row>
    );
  }
  return <></>;
}

export default SegmentStats;
