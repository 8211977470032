import { createSlice } from "@reduxjs/toolkit";

export const routeProfileSlice = createSlice({
  name: "routeProfile",
  initialState: {
    profile: null,
    climbs: [],
    selection: [],
    position: null,
  },
  reducers: {
    updateProfile: (state, action) => {
      state.profile = action.payload;
    },
    updateClimbs: (state, action) => {
      state.climbs = action.payload;
    },
    updateSelection: (state, action) => {
      state.selection = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateProfile, updateClimbs, updateSelection } =
  routeProfileSlice.actions;

export default routeProfileSlice.reducer;
