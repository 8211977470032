import React, {
  useEffect,
  useState,
} from "react";
import {
  convertToHHMMSS,
  convertFromHHMMSS,
  convertToDayJS,
  convertFromDayJsToHHMMSS,
} from "../../../../utils/general";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { TextField } from "@mui/material";
import { PaperCard } from "../../../shared/mui.component";
import { useGetUser } from "../../../../adapters/users.axios";
import { useQuery } from "@tanstack/react-query";
import { calcPower, calcTime } from "./calculations";
import { TargetButtons } from "./targetTimes";

function PowerWidget({ totalElevationGain, climbCategory, kom, qom }) {
  const [weight, setWeight] = useState("");
  const [time, setTime] = useState();
  const [power, setPower] = useState("");
  const [targetTime, setTargetTime] = useState();

  const { data: athleteInfo } = useQuery({
    queryKey: ["user", "info"],
    queryFn: useGetUser,
  });

  useEffect(() => {
    const startWeight = athleteInfo?.weight + 10 || 75;
    const startPower = 200;
    const startTime = calcTime({
      elevation: totalElevationGain,
      weight: startWeight,
      power: startPower,
    });

    setTime(convertToDayJS(startTime));
    setWeight(startWeight);
    setPower(startPower);
  }, [totalElevationGain, athleteInfo]);

  useEffect(() => {
    // Update time and power when changing target time
    if (targetTime === undefined) {
      return;
    }
    if (targetTime) {
      setTime(convertToDayJS(convertToHHMMSS(targetTime)));
      setPower(calcPower({ elevation: totalElevationGain, time: targetTime, weight: weight }));
    } else {
      setTime(convertToDayJS("00:00:00"));
      setPower(0);
    }
  }, [targetTime, totalElevationGain, weight]);

  // Update power when changing weight
  const onChangeWeight = (e) => {
    const weight = parseInt(e.target.value) || 0;
    setWeight(weight);
    const timeHHmmss = convertFromDayJsToHHMMSS(time);
    if (convertFromHHMMSS(timeHHmmss) === 0) {
      setPower(0);
    } else {
      setPower(calcPower({ elevation: totalElevationGain, weight: weight, time: timeHHmmss }));
    }
  };

  // Update time when changing power
  const onChangePower = (e) => {
    // Set target time to undefined to allow for updates when reselecting the same target time
    setTargetTime(undefined);
    const power = parseInt(e.target.value) || 0;
    setPower(power);
    if (power === 0) {
      setTime(convertToDayJS("00:00:00"));
    } else {
      const timeString = calcTime({ elevation: totalElevationGain, power: power, weight: weight });
      setTime(convertToDayJS(timeString));
    }
  };

  // Update time when changing power
  const onChangeTime = (e) => {
    // Set target time to undefined to allow for updates when reselecting the same target time
    setTargetTime(undefined);
    setTime(e);
    const timeHHmmss = convertFromDayJsToHHMMSS(e);
    if (convertFromHHMMSS(timeHHmmss) === 0) {
      setPower(0);
    } else {
      setPower(calcPower({ elevation: totalElevationGain, time: timeHHmmss, weight: weight }));
    }
  };

  // If the climb is not a climb, return a message
  if (climbCategory === 0 || totalElevationGain <= 0) {
    return <PaperCard title="Power widget">{<>Power widget is only available for climbs.</>}</PaperCard>;
  }

  return (
    <PaperCard title="Power widget">
      <>
        <TextField
          label="Weight in kg (rider + bike)"
          type="number"
          value={weight}
          onChange={(e) => onChangeWeight(e)}
          fullWidth
          margin="dense"
          size="small"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            label="Time"
            format="HH:mm:ss"
            onChange={(e) => onChangeTime(e)}
            value={time}
            fullWidth
            margin="dense"
            size="small"
          />
        </LocalizationProvider>
        <TextField
          label="Power (estimated)"
          type="number"
          value={power}
          onChange={(e) => onChangePower(e)}
          fullWidth
          margin="dense"
          size="small"
        />
        <TargetButtons kom={kom} qom={qom} setTargetTime={setTargetTime} />
      </>
    </PaperCard>
  );
}

export default PowerWidget;
