import axios from "./axios_interceptor";

export async function useGetAdminStats() {
  const result = await axios.get("/admin/stats");
  return result.data;
}

export async function useGetStravaUsage() {
  const result = await axios.get("/admin/strava_usage");
  return result.data;
}

export async function useUpdateSegments() {
  const result = await axios.get("/admin/update_segments");
  return result.data;
}

export async function useGetAllUsers() {
  const result = await axios.get("/user/all");
  return result.data;
}

export async function useGetAllActivities() {
  const result = await axios.get("/strava/activities");
  return result.data;
}

export async function fetchMergeActivities(id_1, id_2) {
  try {
    const result = await axios.get(
      `/strava/activity/merge/?id_1=${id_1}&id_2=${id_2}`,
      { responseType: "blob" }
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function callMergeGpx(formData) {
  const result = await axios.post("/merge_gpx", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result.data;
}
