import { Container } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Loading, PageContent } from "../../shared/mui.component";
import ExploreAlerts from "./subAlerts.component";
import ExploreMap from "./subExploreMap.component";
import ExploreOptions from "./subOptions.component";
import SegmentsList from "./subSegmentsList.component";
import Helmet from "../../shared/helmet";
import StravaConnectButton from "../../shared/stravaButton.component";
import { useQuery } from "@tanstack/react-query";
import { useIsAuth } from "../../../adapters/users.axios";

function SegmentExplore() {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const location = searchParams.get("search");

  const { data: isAuth } = useQuery({
    queryKey: ["auth", "isAuth"],
    queryFn: useIsAuth,
  });

  const ContentAuth = () => {
    return (
      <>
        <ExploreMap location={location} />
        <ExploreOptions />
        <SegmentsList />
        <ExploreAlerts />
      </>
    );
  };

  const ContentNoAuth = () => {
    return (
      <>
        <p align="left">
          You need to be connected with Strava to access this page.
        </p>
        <StravaConnectButton />
        <br />
        <ExploreMap location={location} disabled={true} />
      </>
    );
  };

  const Content = () => {
    if (isAuth) {
      return <ContentAuth />;
    } else if (isAuth === undefined) {
      return <Loading />;
    } else {
      return <ContentNoAuth />;
    }
  };

  return (
    <PageContent>
      <Helmet page="explore" />
      <Container align="center" disableGutters>
        <Container align="left" disableGutters style={{ maxWidth: "1200px" }}>
          <h1 align="left">Segment Explorer</h1>
          <Content />
        </Container>
      </Container>
    </PageContent>
  );
}

export default SegmentExplore;
