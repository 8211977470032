import { point, lineString } from "@turf/helpers";
import pointToLineDistance from "@turf/point-to-line-distance";

export function filterPOIs(arrPath, arrPOIs, maxDistance) {
  // Create a list of LineString objects from arrPath
  const lineStrings = [];
  for (let i = 0; i < arrPath.length - 1; i++) {
    const coords = [arrPath[i], arrPath[i + 1]];
    const pathLineString = lineString(coords);
    lineStrings.push(pathLineString);
  }

  // Check each POI against the LineString objects
  const filteredPOIs = [];
  for (let poi of arrPOIs) {
    const poiPoint = point([poi.lat, poi.lon]);

    // Check if the POI is within maxDistance meters of any LineString
    for (let lineString of lineStrings) {
      const result = pointToLineDistance(poiPoint, lineString, {
        units: "meters",
      });
      // Check if the POI is within maxDistance meters of the LineString. If so, add it to the list of filtered POIs and move on to the next POI
      if (result <= maxDistance) {
        filteredPOIs.push(poi);
        break;
      }
    }
  }

  return filteredPOIs;
}

export function prepGeoJSON(arrLatLng) {
  const inputRev = arrLatLng?.map((item) => {
    return [item[1], item[0]];
  });
  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: inputRev,
    },
    properties: {
      name: "Main",
    },
  };
}

export function getElevGain(arrElevation) {
  // Get the sum of all positive deltas in an array
  let sum = 0;
  for (let i = 0; i < arrElevation.length - 1; i++) {
    if (arrElevation[i + 1] > arrElevation[i]) {
      sum += arrElevation[i + 1] - arrElevation[i];
    }
  }
  return sum;
}
