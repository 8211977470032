import dayjs from "dayjs";

export const convertToHHMMSS = (time, skipHour = false) => {
  if (time) {
    const timeNumeric = parseInt(time, 10);
    const hh = addZero(Math.floor(timeNumeric / 3600));
    const mm = addZero(Math.floor((timeNumeric % 3600) / 60));
    const ss = addZero(Math.floor(timeNumeric % 60));
    if (skipHour && hh === "00") {
      return `${mm}:${ss}`;
    }
    return `${hh}:${mm}:${ss}`;
  }
  return "00:00:00";
};

// Returns string as seconds
export const convertFromHHMMSS = (time) => {
  if (time !== null) {
    if (time.includes("s")) {
      return parseInt(time);
    }
    const split = time.split(":");
    if (split.length === 3) {
      return (
        parseInt(split[0] * 60 * 60) +
        parseInt(split[1] * 60) +
        parseInt(split[2])
      );
    }
    if (split.length === 2) {
      return parseInt(split[0] * 60) + parseInt(split[1]);
    }
  } else {
    return null;
  }
};

export const convertToDayJS = (time) => {
  if (time) {
    const [hour, minute, second] = time.split(":");
    // Convert to dayjs object
    const newTime = dayjs()
      .set("hour", hour)
      .set("minute", minute)
      .set("second", second);
    return newTime;
  }
};

export const convertFromDayJsToHHMMSS = (time) => {
  if (time) {
    return time.format("HH:mm:ss");
  }
};

const addZero = (number) => {
  if (number > 9) {
    return number;
  }
  return `0${number}`;
};

export const convertNum = (number, decimals, unit = "") => {
  const factor = Math.pow(10, decimals);
  const roundedNum = Math.round(number * factor) / factor;
  const formatNum = roundedNum.toLocaleString("en-US");
  return `${formatNum}${unit}`;
};

export const findIndex = (array, value) => {
  const index = array.findIndex((item) => item >= value);
  return index;
};

export function formatDateAndTime(dateString) {
  const date = new Date(dateString);
  return date.toISOString().slice(0, 16).replace("T", " ");
}

export const renderObject = (key, value) => {
  if (typeof value === "object") {
    return (
      <li key={key}>
        <strong>{key}:</strong>
        <ul>
          {Object.entries(value).map(([nestedKey, nestedValue]) =>
            renderObject(nestedKey, nestedValue)
          )}
        </ul>
      </li>
    );
  } else {
    return (
      <li key={key}>
        <strong>{key}:</strong> {value.toLocaleString()}
      </li>
    );
  }
};
