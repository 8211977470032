import React, { useCallback, useContext, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { updateSegment } from "../../../adapters/segments.axios";
import { InputMUI } from "../../shared/mui.component";
import { exploreColsMobile } from "../../shared/tables/columns";
import { SegmentSearchContext } from "../../../contexts/SearchContext";

function SegmentsList() {
  const {
    segments,
    setSegments,
    selectedSegments,
    setSelectedSegments,
    setAlertMessage,
  } = useContext(SegmentSearchContext);
  const divRef = useRef();
  const userGridRef = useRef();
  const segmentRef = useRef();

  // Set column config
  const columnConfig = {
    filter: true,
    resizable: true,
    width: divRef.current?.offsetWidth,
    cellStyle: () => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }),
  };

  // Search box for users
  const onFilterTextBoxChanged = useCallback(() => {
    userGridRef.current.api.setQuickFilter(segmentRef.current.value);
  }, []);

  const refreshSegment = async (e) => {
    try {
      const updatedSegment = await updateSegment(e.data.id);

      const updatedIndex = segments.findIndex(
        (x) => x.id === updatedSegment.data.id
      );

      if (updatedIndex !== -1) {
        // Create new arrays to avoid mutating state directly
        const newSegments = [...segments];
        const newSelectedSegments = [...selectedSegments];

        newSegments[updatedIndex] = updatedSegment.data;

        const selectedIndex = newSelectedSegments.findIndex(
          (x) => x.id === updatedSegment.data.id
        );

        if (selectedIndex !== -1) {
          newSelectedSegments[selectedIndex] = updatedSegment.data;
        }

        setSegments(newSegments);
        setSelectedSegments(newSelectedSegments);

        setAlertMessage({
          variant: "success",
          message: `Segment ${updatedSegment.data.id} has been updated`,
        });
      } else {
        setAlertMessage({
          variant: "error",
          message: "Segment not found in the list",
        });
      }
    } catch (error) {
      console.error("Error updating segment:", error);
      setAlertMessage({
        variant: "error",
        message: "Segment has NOT been updated",
      });
    }
  };

  if (segments && segments.length > 0) {
    return (
      <>
        <div
          className="ag-theme-alpine mb-3"
          align="left"
          style={{ width: "100%" }}
          ref={divRef}
        >
          <h3>Result</h3>
          {`Showing top ${selectedSegments.length} segments`} - double-click to
          update a segment
          <InputMUI
            label="Search in list"
            inputRef={segmentRef}
            placeholder="Search..."
            onChange={onFilterTextBoxChanged}
          />
          <AgGridReact
            ref={userGridRef}
            domLayout="autoHeight"
            rowData={selectedSegments}
            rowHeight={60}
            columnDefs={exploreColsMobile}
            defaultColDef={columnConfig}
            onRowDoubleClicked={refreshSegment}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className="ag-theme-alpine mb-3"
          align="left"
          style={{ width: "100%" }}
        >
          <h3>Segment list</h3>
          No segments to show
        </div>
      </>
    );
  }
}

export default SegmentsList;
