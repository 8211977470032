import { PageContent } from "../../shared/mui.component";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import useReadMarkdown from "../../hooks/readMarkdown";
import React from "react";
import Helmet from "../../shared/helmet";

function About() {
  const markdown = useReadMarkdown({ path: require("./about.md") });

  const flatten = (text, child) => {
    return typeof child === "string"
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text);
  };

  /**
   * HeadingRenderer is a custom renderer
   * It parses the heading and attaches an id to it to be used as an anchor
   */
  const HeadingRenderer = (props) => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, "");
    const slug = text.toLowerCase().replace(/\W/g, "-");
    return React.createElement("h" + props.level, { id: slug }, props.children);
  };

  return (
    <PageContent>
      <Helmet page="about" />
      <ReactMarkdown
        children={markdown}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        renderers={{ heading: HeadingRenderer }}
      />
    </PageContent>
  );
}

export default About;
