import React, { useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  deleteMessage,
  getAllMessages,
} from "../../../adapters/feedback.axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Alert, Container } from "@mui/material";
import { defaultColDef } from "../../shared/tables/columns";
import { useMutation, useQuery } from "@tanstack/react-query";
import { formatDateAndTime } from "../../../utils/general";
import ConfirmModal from "../../shared/confirmModal.component";

function MessageList() {
  const gridRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  // Get all messages
  const { data: messages, error } = useQuery({
    queryKey: ["admin", "messages"],
    queryFn: getAllMessages,
  });

  const { mutate } = useMutation({
    mutationFn: deleteMessage,
    onSuccess: () => {
      gridRef.current.api.applyTransaction({ remove: selectedMessage });
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
    onMutate: () => {
      setShowModal(false);
    },
  });

  const messageCols = [
    { field: "user_uuid", hide: true },
    { field: "name", width: 100 },
    {
      field: "message",
      cellStyle: {
        whiteSpace: "normal",
      },
      autoHeight: true,
    },
    { field: "email", width: 150 },
    {
      field: "created_at",
      headerName: "Date",
      valueFormatter: (params) => formatDateAndTime(params.value),
    },
  ];

  const onDeleteMessages = () => {
    if (selectedMessage.length > 0) {
      mutate(selectedMessage[0].uuid);
    }
  };

  return (
    <Container
      disableGutters
      className="ag-theme-alpine mb-4"
      align="left"
      style={{ width: "100%" }}
    >
      <ConfirmModal
        onConfirm={onDeleteMessages}
        title="Delete Messages"
        message="Are you sure you want to delete this message?"
        show={showModal}
        close={() => setShowModal(false)}
      />
      <h3>Messages</h3>
      {error ? <Alert severity="error">{error.message}</Alert> : null}
      {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
      <AgGridReact
        ref={gridRef}
        domLayout="autoHeight"
        rowData={messages}
        columnDefs={messageCols}
        defaultColDef={defaultColDef}
        pagination={true}
        paginationPageSize={5}
        onCellDoubleClicked={(e) => {
          setShowModal(true);
          setSelectedMessage([e.data]);
        }}
      />
    </Container>
  );
}

export default MessageList;
