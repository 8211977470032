import React, { createContext, useState } from "react";
import { PropTypes } from "prop-types";

// create a context object
export const SegmentSearchContext = createContext();

// create a context provider component
export const SegementSearchProvider = ({ children }) => {
  const [segments, setSegments] = useState([]); // All segments found so far
  const [selectedSegments, setSelectedSegments] = useState([]); // All segments on map and comply with options
  const [alertMessage, setAlertMessage] = useState({}); // Alerts

  // Explore options
  const [searchCond, setSearchCond] = useState({
    climb_category: { lowBound: null, uppBound: null },
    distance: { lowBound: null, uppBound: null },
    grade: { lowBound: null, uppBound: null },
    limit: 25,
  });

  // Map location
  const [mapCenter, setMapCenter] = useState(
    JSON.parse(localStorage.getItem("explore")) || [45.764, 4.8357]
  );

  const [mapZoom, setMapZoom] = useState(13);
  const [mapBounds, setMapBounds] = useState();

  return (
    <SegmentSearchContext.Provider
      value={{
        // Segments
        segments,
        setSegments,
        selectedSegments,
        setSelectedSegments,
        alertMessage,
        setAlertMessage,

        // Search conditions
        searchCond,
        setSearchCond,

        // Map characteristics
        mapCenter,
        setMapCenter,
        mapZoom,
        setMapZoom,
        mapBounds,
        setMapBounds,
      }}
    >
      {children}
    </SegmentSearchContext.Provider>
  );
};

SegementSearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
