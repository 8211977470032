import { convertFromHHMMSS, convertToHHMMSS } from "../../../../utils/general";

// Calculates the time based on weight and power
// weight (kg) x 9.8 x elevation gain (meters) / time (seconds) = power (watts).
// Add 10% for rolling and air resistance.
export const calcTime = ({ elevation, weight, power }) => {
    return convertToHHMMSS(((weight * 9.8 * elevation) / (power / 1.1)).toFixed(0));
};

export const calcPower = ({ elevation, weight, time }) => {
    const timeInSeconds = Number.isInteger(time) ? time : convertFromHHMMSS(time);
    return (((weight * 9.8 * elevation) / timeInSeconds) * 1.1).toFixed(0);
};
