import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  useGetAdminStats,
  useUpdateSegments,
} from "../../../adapters/admin.axios";
import { Alert, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { renderObject } from "../../../utils/general";

function SegmentStats() {
  const [firstMount, setFirstMount] = useState(true);
  const [message, setMessage] = useState("");
  const queryClient = useQueryClient();

  const { data: stats, error } = useQuery({
    queryKey: ["admin", "stats"],
    queryFn: useGetAdminStats,
  });

  const {
    data: updatedSegments,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["admin", "segments", "update"],
    queryFn: useUpdateSegments,
    enabled: false,
  });

  useEffect(() => {
    if (firstMount) {
      setFirstMount(false);
      return;
    }
    if (!firstMount && updatedSegments) {
      setMessage("Segments updated");
      queryClient.setQueryData(["admin", "stats"], updatedSegments);
      return;
    }
  }, [queryClient, updatedSegments, firstMount]);

  return (
    <>
      {stats ? (
        <>
          <h3>Stats</h3>
          {error ? <Alert severity="error">{error.message}</Alert> : null}
          {message ? <Alert severity="success">{message}</Alert> : null}
          <ul>
            {Object.entries(stats).map(([key, value]) =>
              renderObject(key, value)
            )}
          </ul>
          <Button
            variant="contained"
            onClick={() => {
              setMessage("");
              refetch();
            }}
            disabled={isFetching}
          >
            Update Segments
          </Button>
          {isFetching ? <CircularProgress /> : null}
        </>
      ) : null}
    </>
  );
}

export default SegmentStats;
