import { Button, ButtonGroup } from "@mui/material";

export const TargetButtons = ({ kom, qom, setTargetTime }) => {
    // Only show buttons if xoms are available
    if (kom || qom) {
        const arrButtons = [];
        const arrBase = [
            { KOM: kom },
            { QOM: qom },
            { Reset: "" },
        ];
        arrBase.forEach((obj, index) => {
            const key = Object.keys(obj)[0];
            const value = obj[key];
            arrButtons.push(
                <Button
                    onClick={() => setTargetTime(value)}
                    key={key}
                    disabled={!value && value !== ""}
                >
                    {key}
                </Button>
            );
        });
        return <ButtonGroup fullWidth={true}>{arrButtons}</ButtonGroup>;
    }
}