import React from "react";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MainMenu from "./mainMenu.component";
import UserMenu from "./userMenu.component";

const ResponsiveAppBar = () => {
  return (
    <AppBar position="static" color="inherit">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo on full view */}
          <Box
            component={Link}
            to="/"
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          >
            <img
              href="/"
              src="/logo_text.png"
              alt="home"
              className="em-1"
              title="Home"
            />
          </Box>
          <MainMenu />
          <Box
            component={Link}
            to="/"
            sx={{ display: { xs: "flex", md: "none" }, mr: 1, flexGrow: 1 }}
          >
            <img
              src="/logo_text.png"
              alt="home"
              className="em-1"
              title="Home"
            />
          </Box>
          <UserMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
