import React, { useContext, useState } from "react";

import { Slider } from "@mui/material";
import { SegmentSearchContext } from "../../../../contexts/SearchContext";

function GradientSlider() {
  const { searchCond, setSearchCond } = useContext(SegmentSearchContext);
  const [range, setRange] = useState([-11, 11]);

  const handleChange = (value) => {
    setRange(value);
    const lowBound = value[0] === -11 ? null : value[0];
    const uppBound = value[1] === 11 ? null : value[1];
    setSearchCond({
      ...searchCond,
      grade: { lowBound: lowBound, uppBound: uppBound },
    });
  };

  const marks = [
    { value: -11, label: "..." },
    { value: -10, label: "-10%" },
    { value: 0, label: "0%" },
    { value: 10, label: "10%" },
    { value: 11, label: "..." },
  ];

  const rangeText = () => {
    const lowRange = range[0] === -11 ? "-∞" : range[0];
    const highRange = range[1] === 11 ? "∞" : range[1];
    return `[${lowRange}%, ${highRange}%]`;
  };

  return (
    <div>
      Gradient range {rangeText()}
      <div className="mx-2">
        <Slider
          getAriaLabel={() => "Gradient range"}
          value={range}
          onChange={(e, v) => handleChange(v)}
          valueLabelDisplay="auto"
          marks={marks}
          min={-11}
          max={11}
        />
      </div>
    </div>
  );
}

export default GradientSlider;
