import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageContent } from "../../shared/mui.component";
import Helmet from "../../shared/helmet";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <PageContent>
      <Helmet page="notFound" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1>Page not found</h1>
          <img
            src="https://media.giphy.com/media/2oUfvvUgQHnLsQWFMW/giphy.gif"
            alt="No found"
            style={{ maxWidth: "100%" }}
          />
          <Button onClick={() => navigate("/")}>Bring me home</Button>
        </Box>
      </Box>
    </PageContent>
  );
};

export default NotFound;
