import React, { useRef, useEffect, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Box, Container, Divider, IconButton } from "@mui/material";
import ErrorMessage from "../../shared/errorMessage.component";
import { climbStatsColsMobile } from "../../shared/tables/columns";
import { calcClimbScore } from "../../../utils/calculations";
import DeleteIcon from "@mui/icons-material/Delete";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SettingsIcon from "@mui/icons-material/Settings";
import ClimbStatsModal from "./climbStatsModel.component";
import { updateClimbs, updateSelection } from "./slicers/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import ClimbSettings from "./climbSettings.component";

// Table containing climb statistics for all climbs provided. Option to remove, combine or modify defined climbs
function ClimbStats({ resetClimbs }) {
  const divRef = useRef();
  const gridRef = useRef();
  const [tableData, setTableData] = useState();
  const [showTable, setShowTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const [showErr, setShowErr] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  // Get redux store items
  const profileData = useSelector(
    (state) => state.routeAnalysisProfile.profile
  );
  const profileClimbs = useSelector(
    (state) => state.routeAnalysisProfile.climbs
  );
  const climbSelection = useSelector(
    (state) => state.routeAnalysisProfile.selection
  );

  const dispatch = useDispatch();

  const defaultColDef = {
    width: divRef.current?.offsetWidth - 52,
    cellStyle: () => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }),
  };

  function onSelectionChanged() {
    const selectedRows = gridRef.current.api.getSelectedRows();
    dispatch(updateSelection(selectedRows));
  }

  // Delete selected climbs
  const deleteClimb = () => {
    const toDel = climbSelection.map(function (value) {
      return value.index;
    });
    if (toDel.length > 0) {
      const myArray = profileClimbs.filter(function (obj) {
        return !toDel.includes(obj.index);
      });
      const arrClimbs = [];
      myArray.forEach((item, index) => {
        arrClimbs.push({ ...item, index: index + 1 });
      });
      dispatch(updateClimbs(arrClimbs));
      dispatch(updateSelection([]));
    } else {
      setErrMsg("Select at least 1 climb to delete.");
      setShowErr(true);
    }
  };

  // Merge selected climbs
  const mergeClimbs = () => {
    const toMerge = gridRef.current.api.getSelectedRows().map(function (value) {
      return value.index;
    });
    const diffArr = toMerge.slice(1).map(function (n, i) {
      return n - toMerge[i];
    });
    // Only merge if selected climbs are consecutive and more than 1 is selected
    if (diffArr.every((value) => value === 1) && toMerge.length > 1) {
      const climbFirst = toMerge[0];
      const climbLast = toMerge[toMerge.length - 1];
      const arrClimbs = [];

      profileClimbs.forEach((item, index) => {
        if (index < climbFirst - 1 || index > climbLast - 1) {
          arrClimbs.push({ ...item });
        } else if (index === climbFirst - 1) {
          arrClimbs.push({
            start: profileClimbs[climbFirst - 1].start,
            end: profileClimbs[climbLast - 1].end,
          });
        }
      });
      arrClimbs.forEach((item, index) => {
        item.index = index + 1;
      });
      dispatch(updateClimbs(arrClimbs));
      dispatch(updateSelection([]));
    } else {
      setErrMsg(
        "Select at least 2 climbs; all selected climbs must be consecutive."
      );
      setShowErr(true);
    }
  };

  const arrClimbAnalysisInput = useMemo(() => {
    const arrResult = [];
    if (!tableData) {
      return [];
    }
    tableData.forEach((item) => {
      const climbInfo = {
        label: `Climb ${item.index}/${profileClimbs.length}`,
        distance: item.stats.distEnd - item.stats.distStart,
        elevationGain: item.stats.elevEnd - item.stats.elevStart,
        gradient:
          (item.stats.elevEnd - item.stats.elevStart) /
          (item.stats.distEnd - item.stats.distStart),
        startsAt: item.stats.distStart,
        endsAt: item.stats.distEnd,
        score: calcClimbScore(
          item.stats.elevStart,
          item.stats.elevEnd,
          item.stats.distEnd - item.stats.distStart
        ),
      };
      const climbProfile = {
        distance: profileData.distance.slice(item.start, item.end),
        altitude: profileData.elevation.slice(item.start, item.end),
        latlng: profileData.latlng.slice(item.start, item.end),
      };
      arrResult.push({ climbInfo: climbInfo, climbProfile: climbProfile });
    });
    return arrResult;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  // Recalculate climbs with current parameter settings
  const reset = () => {
    resetClimbs();
  };

  // Enhance climb information with profileData data
  useEffect(() => {
    const arrResult = [];
    profileClimbs.forEach((item) => {
      arrResult.push({
        start: item.start,
        end: item.end,
        index: item.index,
        stats: {
          distStart: profileData.distance[item.start],
          distEnd: profileData.distance[item.end],
          elevStart: profileData.elevation[item.start],
          elevEnd: profileData.elevation[item.end],
        },
        color: "red",
      });
    });
    setTableData(arrResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileClimbs]);

  // Return component
  if (profileClimbs.length > 0 || showTable) {
    if (!showTable) {
      setShowTable(true);
    }
    return (
      <div className="my-2">
        <ClimbSettings show={showSettings} setShow={setShowSettings} />

        <ErrorMessage show={showErr} setShow={setShowErr} message={errMsg} />
        <ClimbStatsModal
          arrClimbs={arrClimbAnalysisInput}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <Container
          className="ag-theme-alpine p-0 my-0"
          align="left"
          disableGutters
        >
          <div className="mb-2" ref={divRef}>
            <AgGridReact
              ref={gridRef}
              domLayout="autoHeight"
              rowData={tableData}
              rowHeight={60}
              columnDefs={climbStatsColsMobile}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              onSelectionChanged={onSelectionChanged}
              pagination={false}
            />
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <IconButton onClick={() => setShowModal(true)}>
              <QueryStatsIcon />
            </IconButton>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                backgroundColor: "#000", // Example: use a darker color like black
                // You can also adjust the width if needed
              }}
            />
            <IconButton
              disabled={climbSelection.length === 0}
              onClick={deleteClimb}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              disabled={climbSelection.length <= 1}
              onClick={mergeClimbs}
            >
              <CallMergeIcon />
            </IconButton>
            <IconButton onClick={reset}>
              <RestartAltIcon />
            </IconButton>
            <IconButton onClick={() => setShowSettings(true)}>
              <SettingsIcon />
            </IconButton>
          </Box>
        </Container>
      </div>
    );
  }
}

export default ClimbStats;
