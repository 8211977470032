import React, { useEffect, useMemo, useState } from "react";
import {
  GeoJSON,
  Polyline,
  Tooltip,
  CircleMarker,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  CLIMB_COLOR,
  HIGHLIGHT_COLOR,
  PATH_COLOR,
} from "../../../utils/constants";
import { iconStyles } from "../../shared/map/icons";
import { Alert, Container } from "@mui/material";
import useAmenities from "../../hooks/amenities";
import { prepGeoJSON } from "../../../utils/gpx";
import { AmenitiesButtons, AmenitiesCluster } from "../../shared/map/poiItems";
import MapWrapper, {
  CustomButton,
} from "../../shared/map/mapWrapper.component";
import { useDispatch, useSelector } from "react-redux";
import { updatePosition } from "./slicers/positionSlice";

function SegmentMap() {
  const [map, setMap] = useState(null);
  const [showAmenities, setShowAmenities] = useState("");
  const [bounds, setBounds] = useState([]);

  // Get redux store items
  const routeCoords = useSelector(
    (state) => state.routeAnalysisProfile.profile?.latlng
  );
  const routeClimbs = useSelector((state) => state.routeAnalysisProfile.climbs);
  const stats = useSelector(
    (state) => state.routeAnalysisProfile.profile?.stats
  );
  const climbSelection = useSelector(
    (state) => state.routeAnalysisProfile.selection
  );
  const routePositionIndex = useSelector(
    (state) => state.routeAnalysisPosition.index
  );
  const [cursorPosition, setCursorPosition] = useState(null);

  const dispatch = useDispatch();

  // Update cursor position on change of routePositionIndex
  useEffect(() => {
    if (routePositionIndex) {
      setCursorPosition(routeCoords[routePositionIndex]);
    } else {
      setCursorPosition(null);
    }
  }, [routePositionIndex, routeCoords]);

  const getCursorOnRoute = (cursor) => {
    // Check if the cursor is on the route using a simple distance threshold
    const threshold = 250; // Adjust as needed
    const index = routeCoords.findIndex(
      (point) => cursor.distanceTo(point) < threshold
    );

    if (index !== -1) {
      dispatch(updatePosition(index));
    } else {
      dispatch(updatePosition(index));
    }
  };

  const handleMouseMove = (e) => {
    getCursorOnRoute(e.latlng);
  };

  const MapEvents = () => {
    useMapEvents({
      mousemove: handleMouseMove,
    });

    return null;
  };

  // Amenities
  const { updateAmenities, amenities, error: errorPOI } = useAmenities();

  // Calculate POIs - DON'T ADD updateAmenities TO DEPENDENCIES
  useMemo(() => {
    if (routeCoords) {
      updateAmenities({ arrLatLng: routeCoords });
    }
  }, [routeCoords]);

  // Amenities
  const handlePOISelection = (event, selection) => {
    if (showAmenities === selection) {
      setShowAmenities("");
    } else {
      setShowAmenities(selection);
    }
  };

  // Find and update bounds
  useMemo(() => {
    const arrayLat = routeCoords?.map(function (x) {
      return x[0];
    });
    const arrayLng = routeCoords?.map(function (x) {
      return x[1];
    });
    if (arrayLat && arrayLng) {
      setBounds([
        [Math.min(...arrayLat), Math.min(...arrayLng)],
        [Math.max(...arrayLat), Math.max(...arrayLng)],
      ]);
    }
  }, [routeCoords]);

  // Center map on bounds
  const centerMap = () => {
    map?.flyToBounds(bounds);
  };

  // Reverses lat and lng and returns GeoJSON format
  const geoData = useMemo(() => {
    return prepGeoJSON(routeCoords);
  }, [routeCoords]);

  const StartAndEnd = () => {
    return (
      <>
        <CircleMarker
          center={routeCoords[0]}
          color="green"
          radius={8}
          fillOpacity={1}
        >
          <Tooltip>Start of your route</Tooltip>
        </CircleMarker>
        <CircleMarker
          center={routeCoords[routeCoords.length - 1]}
          color="red"
          radius={8}
          fillOpacity={1}
        >
          <Tooltip>End of your route</Tooltip>
        </CircleMarker>
      </>
    );
  };

  // Highlights array of paths in specified color
  function Highlights({ arrSections = [], color }) {
    const arrSegments = [];

    arrSections.forEach((item) => {
      const positions = routeCoords.slice(item.start, item.end + 1);
      const segment = (
        <Polyline
          pathOptions={{ color: color }}
          positions={positions}
          key={`hl_${item.index}`}
          eventHandlers={{
            mouseover: (e) => {
              e.target.openTooltip(e.latlng);
              e.target.setStyle({
                color: HIGHLIGHT_COLOR,
              });
            },
            mouseout: (e) => {
              e.target.closeTooltip();
              e.target.setStyle({
                color: color,
              });
            },
          }}
        >
          <Tooltip>{`Climb ${item.index}`}</Tooltip>
        </Polyline>
      );
      arrSegments.push(segment);
    });

    return <>{arrSegments}</>;
  }

  // Route path and climb highlights
  const RoutePath = () => {
    return (
      <>
        <GeoJSON
          data={geoData}
          key={geoData.geometry.coordinates[5] || null}
          style={{ color: PATH_COLOR }}
        />
        <Highlights arrSections={routeClimbs} color={CLIMB_COLOR} />
        <Highlights arrSections={climbSelection} color={HIGHLIGHT_COLOR} />
      </>
    );
  };

  if (routeCoords) {
    return (
      <>
        <Container align="center" disableGutters>
          <p style={{ marginBottom: 0, fontWeight: "bold" }}>{stats.name}</p>
          <p style={{ marginTop: 0 }}>
            {(stats.distance / 1000).toFixed(1)}km -{" "}
            {stats.elevation?.pos.toFixed(0)}m
          </p>
        </Container>
        <Container
          align="center"
          disableGutters
          sx={{ height: "300px", maxWidth: "800px" }}
        >
          {errorPOI ? (
            <Alert
              severity="error"
              children={errorPOI}
              sx={{ marginBottom: 2 }}
            />
          ) : null}
          <MapWrapper
            mapRef={setMap}
            bounds={bounds}
            flyOnLoad={false}
            onMouseMove={handleMouseMove}
          >
            <MapEvents />
            {cursorPosition ? (
              <CircleMarker
                center={cursorPosition}
                color="blue"
                radius={4}
                fillOpacity={1}
              />
            ) : null}
            <RoutePath />
            <StartAndEnd />
            <AmenitiesCluster items={amenities} selected={showAmenities} />
            {errorPOI || !amenities ? null : (
              <AmenitiesButtons
                value={showAmenities}
                onChange={handlePOISelection}
                sxGroup={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  zIndex: 1000,
                }}
                sxButton={iconStyles}
              />
            )}
            <CustomButton label="Center map" handleClick={() => centerMap()} />
          </MapWrapper>
        </Container>
      </>
    );
  } else {
    return null;
  }
}

export default SegmentMap;
