import React, { useContext, useState } from "react";

import { Slider } from "@mui/material";
import { SegmentSearchContext } from "../../../../contexts/SearchContext";

function ClimbCatSlider() {
  const { searchCond, setSearchCond } = useContext(SegmentSearchContext);

  const [range, setRange] = useState([0, 5]);

  const handleChange = (value) => {
    setRange(value);
    const lowBound = value[0] === 0 ? null : value[0];
    const uppBound = value[1] === 5 ? null : value[1];
    setSearchCond({
      ...searchCond,
      climb_category: { lowBound: lowBound, uppBound: uppBound },
    });
  };

  const marks = [
    { value: 0, label: "NC" },
    { value: 1, label: "4" },
    { value: 2, label: "3" },
    { value: 3, label: "2" },
    { value: 4, label: "1" },
    { value: 5, label: "HC" },
  ];

  return (
    <div>
      Climb category range
      <div className="mx-2">
        <Slider
          getAriaLabel={() => "Climb category range"}
          value={range}
          onChange={(e, v) => handleChange(v)}
          valueLabelDisplay="off"
          marks={marks}
          min={0}
          max={5}
        />
      </div>
    </div>
  );
}

export default ClimbCatSlider;
