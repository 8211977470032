import { removePosition } from "./utils";
import { saveAs } from "file-saver";

export const downloadPng = (svgRef, stats) => {
  // Downloads the active SVG as a PNG file
  if (svgRef.current) {
    removePosition(svgRef);
    const svg = svgRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);

    const canvas = document.createElement("canvas");
    const svgSize = svg.getBoundingClientRect();
    const scale = 3; // Increase this factor to improve resolution (e.g., 2, 3, 4)
    const canvasMargin = 20; // Margin around the SVG

    // Scale the canvas size for higher resolution
    canvas.width = (svgSize.width + canvasMargin * 2) * scale;
    canvas.height = (svgSize.height + canvasMargin * 2) * scale;

    const ctx = canvas.getContext("2d");

    // Scale your drawing to the increased canvas size
    ctx.scale(scale, scale);

    // Optionally set the background color if you want
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const img = new Image();
    img.src =
      "data:image/svg+xml;base64," +
      btoa(decodeURIComponent(encodeURIComponent(svgData)));

    const fileName = stats?.name
      ? `Profile ${stats?.name} (PMR).png`
      : "prep-my-ride.png";
    const watermark = "prepmyride.cc";
    const textMargin = 10;
    img.onload = () => {
      ctx.drawImage(img, canvasMargin, canvasMargin);

      // Set text properties
      ctx.fillStyle = "black"; // Text color
      ctx.font = "italic 16px Arial"; // Text font and size
      ctx.textAlign = "right"; // Align text to the right

      // Draw the text aligned to the right and above the bottom edge of the canvas
      ctx.fillText(
        watermark,
        canvas.width / scale - textMargin - canvasMargin, // Position text from the right edge
        canvas.height / scale - textMargin
      );

      canvas.toBlob((blob) => {
        saveAs(blob, fileName);
      });
    };
  }
};
