import React, { useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useGetAllUsers } from "../../../adapters/users.axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Container } from "@mui/material";
import { InputMUI } from "../../shared/mui.component";
import { defaultColDef, userCols } from "../../shared/tables/columns";
import { useQuery } from "@tanstack/react-query";

function UsersList() {
  const userGridRef = useRef();
  const searchRef = useRef();

  const { data: userRows } = useQuery({
    queryKey: ["user", "all"],
    queryFn: useGetAllUsers,
  });

  // Search box for users
  const onFilterTextBoxChanged = useCallback(() => {
    userGridRef.current.api.setQuickFilter(searchRef.current.value);
  }, []);

  return (
    <Container
      disableGutters
      className="ag-theme-alpine mb-4"
      align="left"
      style={{ width: "100%" }}
    >
      <h3>Users</h3>
      <InputMUI
        label="Search in list"
        inputRef={searchRef}
        placeholder="Search..."
        onChange={onFilterTextBoxChanged}
      />
      <AgGridReact
        ref={userGridRef}
        domLayout="autoHeight"
        rowData={userRows}
        columnDefs={userCols}
        rowSelection="multiple"
        defaultColDef={defaultColDef}
        pagination={true}
        paginationPageSize={5}
      />
    </Container>
  );
}

export default UsersList;
