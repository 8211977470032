import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Helmet from "./shared/helmet";
import { useQuery } from "@tanstack/react-query";
import { useLogout } from "../adapters/users.axios";

function Logout() {
  const [error, setError] = useState("");
  useEffect(() => {
    handleLogout();
  });
  const { data, refetch } = useQuery({
    queryKey: ["auth", "logout"],
    queryFn: useLogout,
    enabled: false,
  });

  async function handleLogout() {
    try {
      // Delete session from database
      refetch();
      // Delete session from browser
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      // Redirect to home page
      window.location.replace("/");
    } catch {
      setError("Failed to log out, please try again");
    }
  }

  if (error) {
    return (
      <>
        <Helmet page="logout" />
        <Alert severity="error">{error}</Alert>
      </>
    );
  }
}

export default Logout;
