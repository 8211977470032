import React, { useState } from "react";
import { Fab } from "@mui/material";
import ContactModal from "../pages/contact/contactForm.component";
import FeedbackIcon from "@mui/icons-material/Feedback";

function FeedbackBubble() {
  const [openContactModal, setOpenContactModal] = useState(false);
  const [show, setShow] = useState(true);

  const handleOpen = () => setOpenContactModal(true);
  const handleClose = () => setOpenContactModal(false);

  const FeedbackBubble = () => {
    return (
      <>
        <Fab
          color="secondary"
          aria-label="add"
          style={{ position: "fixed", bottom: 10, left: 10 }}
          onClick={handleOpen}
          size="small"
        >
          <FeedbackIcon />
        </Fab>
        <ContactModal
          show={openContactModal}
          close={handleClose}
          hide={() => setShow(false)}
        />
      </>
    );
  };

  return show ? <FeedbackBubble /> : null;
}

export default FeedbackBubble;
