import { useMediaQuery, useTheme } from "@mui/material";

const useDeviceType = () => {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isLg = useMediaQuery(theme.breakpoints.down("xl"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  if (isXs) {
    return 0;
  } else if (isSm) {
    return 1;
  } else if (isMd) {
    return 2;
  } else if (isLg) {
    return 3;
  } else if (isXl) {
    return 4;
  }

  return 2;
};

export default useDeviceType;
