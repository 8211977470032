import { Box, Button, SwipeableDrawer, Modal, Grid } from "@mui/material";
import React, { useRef } from "react";
import { InputMUI, Puller } from "../../shared/mui.component";
import { useDispatch, useSelector } from "react-redux";
import { resetParams, updateParams } from "./slicers/parameterSlice";
import { isMobile } from "react-device-detect";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 12,
  pt: 2,
  px: 4,
  pb: 3,
};

const drawerStyle = {
  "& .MuiDrawer-paper": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: "20px",
  },
};

function ClimbSettings({ show, setShow }) {
  // Get climb parameters from redux store
  const routeAnalysisParams = useSelector((state) => state.routeAnalysisParams);
  const dispatch = useDispatch();

  // Update parameters in redux store
  const updateParameters = (minLength, minGradient, minDistBetween) => {
    dispatch(updateParams({ minLength, minGradient, minDistBetween }));
    setShow(false);
  };

  // TODO: ensure placeholders are updated after reset
  const resetParameters = () => {
    dispatch(resetParams());
    setShow(false);
  };

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={show}
      onClose={() => setShow(false)}
      sx={drawerStyle}
    >
      <Puller />
      <SettingsForm
        routeAnalysisParams={routeAnalysisParams}
        updateParameters={updateParameters}
        resetParameters={resetParameters}
      />
    </SwipeableDrawer>
  ) : (
    <Modal open={show} onClose={() => setShow(false)}>
      <Box sx={modalStyle}>
        <SettingsForm
          routeAnalysisParams={routeAnalysisParams}
          updateParameters={updateParameters}
          resetParameters={resetParameters}
        />
      </Box>
    </Modal>
  );
}

const SettingsForm = ({
  routeAnalysisParams,
  updateParameters,
  resetParameters,
}) => {
  const minLengthRef = useRef(null);
  const minGradientRef = useRef(null);
  const minDistBetweenRef = useRef(null);

  const handleUpdate = () => {
    updateParameters(
      minLengthRef.current?.value,
      minGradientRef.current?.value,
      minDistBetweenRef.current?.value
    );
  };

  return (
    <>
      <h2>Settings</h2>
      <InputMUI
        label={"Minimum length (m)"}
        ref={minLengthRef}
        placeholder={String(routeAnalysisParams.minLength)}
        defaultValue={routeAnalysisParams.minLength}
        type="number"
      />
      <InputMUI
        label="Minimum gradient (%)"
        ref={minGradientRef}
        placeholder={String(routeAnalysisParams.minGradient)}
        defaultValue={routeAnalysisParams.minGradient}
        type="number"
      />
      <InputMUI
        label="Minimum distance between climbs (m)"
        ref={minDistBetweenRef}
        placeholder={String(routeAnalysisParams.minDistBetween)}
        defaultValue={routeAnalysisParams.minDistBetween}
        type="number"
      />

      <ActionButtons
        handleUpdate={handleUpdate}
        resetParameters={resetParameters}
      />
    </>
  );
};

const ActionButtons = ({ handleUpdate, resetParameters }) => (
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs>
      <Button variant="contained" fullWidth onClick={() => handleUpdate()}>
        Update
      </Button>
    </Grid>
    <Grid item xs>
      <Button
        variant="outlined"
        color="error"
        fullWidth
        onClick={() => resetParameters()}
      >
        Reset
      </Button>
    </Grid>
  </Grid>
);

export default ClimbSettings;
