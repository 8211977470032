import React, { useMemo, useState } from "react";
import { GeoJSON, Tooltip, CircleMarker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { PATH_COLOR } from "../../../../utils/constants";
import MapWrapper, { CustomButton } from "../../map/mapWrapper.component";
import { Container } from "@mui/material";

function SegmentInfoMap({ latLngArray, show }) {
  // Find and update bounds
  const [bounds, setBounds] = useState([]);
  const [map, setMap] = useState(null);

  useMemo(() => {
    const arrayLat = latLngArray?.map(function (x) {
      return x[0];
    });
    const arrayLng = latLngArray?.map(function (x) {
      return x[1];
    });
    if (arrayLat && arrayLng) {
      setBounds([
        [Math.min(...arrayLat), Math.min(...arrayLng)],
        [Math.max(...arrayLat), Math.max(...arrayLng)],
      ]);
    }
  }, [latLngArray]);

  // Reverses lat and lng and returns GeoJSON format
  const geoData = useMemo(() => {
    const inputRev = latLngArray?.map((item) => {
      return [item[1], item[0]];
    });
    return {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: inputRev,
      },
      properties: {
        name: "Main",
      },
    };
  }, [latLngArray]);

  const StartAndEnd = () => {
    return (
      <>
        <CircleMarker
          center={latLngArray[0]}
          color="green"
          radius={8}
          fillOpacity={1}
        >
          <Tooltip>Start</Tooltip>
        </CircleMarker>
        <CircleMarker
          center={latLngArray[latLngArray.length - 1]}
          color="red"
          radius={8}
          fillOpacity={1}
        >
          <Tooltip>End</Tooltip>
        </CircleMarker>
      </>
    );
  };

  // Route path and climb highlights
  const RoutePath = () => {
    return (
      <>
        <GeoJSON
          data={geoData}
          key={geoData.geometry.coordinates[5] || null}
          style={{ color: PATH_COLOR }}
        />
      </>
    );
  };

  // Center map on bounds
  const centerMap = () => {
    map?.flyToBounds(bounds);
  };

  if (show && latLngArray) {
    return (
      <div className="mb-3 mt-1">
        <Container
          align="center"
          disableGutters
          sx={{ height: "300px", maxWidth: "800px" }}
        >
          <MapWrapper mapRef={setMap} bounds={bounds} flyOnLoad={false}>
            <RoutePath />
            <StartAndEnd />
            <CustomButton label="Center map" handleClick={() => centerMap()} />
          </MapWrapper>
        </Container>
      </div>
    );
  } else {
    return null;
  }
}

export default SegmentInfoMap;
