import React from "react";
import { useQuery } from "@tanstack/react-query";

import { PageContent } from "../../shared/mui.component";
import Helmet from "../../shared/helmet";
import { useIsAdmin } from "../../../adapters/users.axios";
import MessageList from "./messageList.component";
import UsersList from "./usersList.component";
import SegmentStats from "./segmentStats.component";
import StravaApiUsage from "./stravaApiUsage.component";
import MergeActivities from "./mergeActivities.component";
import MergeGpx from "./mergeGpx.component";

function Admin() {
  const { data: isAdmin } = useQuery({
    queryKey: ["auth", "isAdmin"],
    queryFn: useIsAdmin,
  });

  return (
    <PageContent>
      <Helmet page="admin" />
      <h2>Admin page</h2>
      {isAdmin ? (
        <>
          <UsersList />
          <MessageList />
          <StravaApiUsage />
          <SegmentStats />
          <MergeActivities />
          <MergeGpx />
        </>
      ) : (
        <p>You do not have access to this page.</p>
      )}
    </PageContent>
  );
}

export default Admin;
