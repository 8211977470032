import { createSlice } from "@reduxjs/toolkit";

export const routePositionSlice = createSlice({
  name: "routePosition",
  initialState: {
    index: null,
  },
  reducers: {
    updatePosition: (state, action) => {
      state.index = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePosition } = routePositionSlice.actions;

export default routePositionSlice.reducer;
